import { Form, FormProps, Input } from "antd";
import { useNavigate } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import AdminLayout from "../../../layouts/AdminLayout";
import { createSupplierAsync } from "../../../store/features/NP/supplier/supplierAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { SupplierData } from "../../../types/redux/NP/supplier";

const navLink = "/sa/supplier";

export default function ManageSupplier() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { postLoading } = useAppSelector((state) => state.network);

  const onFinish: FormProps<SupplierData>["onFinish"] = (values) => {
    dispatch(
      createSupplierAsync({
        data: values,
        others: {
          reRender,
        },
      })
    );
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  return (
    <AdminLayout title="Add New Supplier" headerTitle="Add New Supplier">
      <CustomForm<SupplierData>
        onFinish={onFinish}
        title="Add New Supplier"
        formTitle="Add new supplier information"
        backLink={navLink}
        disabled={postLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="supplierName"
          label="Supplier Name"
          rules={[
            {
              required: true,
              message: "Please enter supplier name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter supplier name" />
        </Form.Item>
        <Form.Item
          name="supplierAddress"
          label="Supplier Address"
          rules={[
            {
              required: true,
              message: "Please enter supplier address",
            },
          ]}
        >
          <Input size="large" placeholder="Enter supplier address" />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
