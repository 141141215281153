export default function PhoneIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="#F0F3F9" />
      <path
        d="M21.5 29.75C21.3011 29.75 21.1103 29.829 20.9697 29.9697C20.829 30.1103 20.75 30.3011 20.75 30.5C20.75 30.6989 20.829 30.8897 20.9697 31.0303C21.1103 31.171 21.3011 31.25 21.5 31.25H24.5C24.6989 31.25 24.8897 31.171 25.0303 31.0303C25.171 30.8897 25.25 30.6989 25.25 30.5C25.25 30.3011 25.171 30.1103 25.0303 29.9697C24.8897 29.829 24.6989 29.75 24.5 29.75H21.5Z"
        fill="#8897AE"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.625 11.75C18.7299 11.75 17.8714 12.1056 17.2385 12.7385C16.6056 13.3714 16.25 14.2299 16.25 15.125V30.875C16.25 31.7701 16.6056 32.6286 17.2385 33.2615C17.8714 33.8944 18.7299 34.25 19.625 34.25H26.375C27.2701 34.25 28.1286 33.8944 28.7615 33.2615C29.3944 32.6286 29.75 31.7701 29.75 30.875V15.125C29.75 14.2299 29.3944 13.3714 28.7615 12.7385C28.1286 12.1056 27.2701 11.75 26.375 11.75H19.625ZM18.5 15.125C18.5 14.504 19.004 14 19.625 14H20.75V14.375C20.75 14.996 21.254 15.5 21.875 15.5H24.125C24.746 15.5 25.25 14.996 25.25 14.375V14H26.375C26.996 14 27.5 14.504 27.5 15.125V30.875C27.5 31.496 26.996 32 26.375 32H19.625C19.3266 32 19.0405 31.8815 18.8295 31.6705C18.6185 31.4595 18.5 31.1734 18.5 30.875V15.125Z"
        fill="#8897AE"
      />
    </svg>
  );
}
