export default function NetworkIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.28801 15.038C9.27255 14.0536 10.6078 13.5006 12 13.5006C13.3922 13.5006 14.7275 14.0536 15.712 15.038M5.10601 11.856C8.91301 8.04799 15.086 8.04799 18.894 11.856M1.92401 8.67399C7.48901 3.10899 16.511 3.10899 22.076 8.67399M12.53 18.22L12 18.75L11.47 18.22C11.6106 18.0795 11.8013 18.0007 12 18.0007C12.1988 18.0007 12.3894 18.0795 12.53 18.22Z"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
