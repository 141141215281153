export default function SuccessTimelineIcon({ color = "#33AB29" }) {
  return (
    <svg
      width="19"
      height="19"
      viewBox="0 0 19 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.15625 9.5C0.15625 4.33937 4.33937 0.15625 9.5 0.15625C14.6606 0.15625 18.8437 4.33937 18.8437 9.5C18.8437 14.6606 14.6606 18.8437 9.5 18.8437C4.33937 18.8437 0.15625 14.6606 0.15625 9.5ZM12.9596 7.76158C13.0171 7.68501 13.0587 7.59761 13.082 7.5047C13.1053 7.41177 13.1097 7.31513 13.0951 7.22046C13.0804 7.1258 13.047 7.03499 12.9969 6.95342C12.9466 6.87184 12.8806 6.80112 12.8027 6.74541C12.7247 6.68972 12.6364 6.65015 12.543 6.62905C12.4496 6.60795 12.3529 6.60575 12.2586 6.62257C12.1643 6.63938 12.0743 6.67488 11.9939 6.72698C11.9135 6.77907 11.8443 6.84671 11.7904 6.92592L8.68925 11.2672L7.13292 9.71083C6.99667 9.58385 6.81646 9.51476 6.63025 9.51802C6.44405 9.52137 6.26638 9.59679 6.1347 9.72847C6.00301 9.86014 5.92757 10.0378 5.92429 10.224C5.92101 10.4102 5.99012 10.5904 6.11708 10.7267L8.27333 12.8829C8.34713 12.9566 8.43606 13.0134 8.534 13.0493C8.63194 13.0852 8.7365 13.0994 8.84047 13.0908C8.94436 13.0822 9.04527 13.0511 9.13593 12.9997C9.22668 12.9483 9.30517 12.8776 9.36583 12.7928L12.9596 7.76158Z"
        fill={color}
      />
    </svg>
  );
}
