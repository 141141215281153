export default function UserIcon() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="23" cy="23" r="23" fill="#F0F3F9" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.5 17C18.5 15.8065 18.9741 14.6619 19.818 13.818C20.6619 12.9741 21.8065 12.5 23 12.5C24.1935 12.5 25.3381 12.9741 26.182 13.818C27.0259 14.6619 27.5 15.8065 27.5 17C27.5 18.1935 27.0259 19.3381 26.182 20.182C25.3381 21.0259 24.1935 21.5 23 21.5C21.8065 21.5 20.6619 21.0259 19.818 20.182C18.9741 19.3381 18.5 18.1935 18.5 17ZM14.751 31.105C14.7847 28.9395 15.6686 26.8741 17.212 25.3546C18.7553 23.8351 20.8342 22.9834 23 22.9834C25.1658 22.9834 27.2447 23.8351 28.788 25.3546C30.3314 26.8741 31.2153 28.9395 31.249 31.105C31.2516 31.2508 31.2117 31.3942 31.134 31.5176C31.0564 31.641 30.9445 31.7392 30.812 31.8C28.3612 32.9237 25.6962 33.5037 23 33.5C20.214 33.5 17.567 32.892 15.188 31.8C15.0555 31.7392 14.9436 31.641 14.866 31.5176C14.7884 31.3942 14.7484 31.2508 14.751 31.105Z"
        fill="#8897AE"
      />
    </svg>
  );
}
