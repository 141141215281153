import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import CustomTable from "../../../components/common/CustomTable";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { towerBulkMailAsync } from "../../../store/features/NP/network/networkAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { formatDate } from "../../../utils/time";

export default function Alarm() {
  const dispatch = useAppDispatch();
  const { bulkLoading, towerBulkMail } = useAppSelector(
    (state) => state.network
  );
  const { handlePagination, page } = useTable();
  //   const location = useLocation();
  //   const path = pathLink(location.pathname, 1);

  const columns: ColumnsType<any> = [
    {
      title: "Type",
      dataIndex: "type",
      render: (val) => val?.toUpperCase(),
    },
    {
      title: "Time",
      dataIndex: "time",
    },
    {
      title: "Created Date",
      dataIndex: "createdAt",

      render: (val) => (val ? formatDate(val, "DD/MM/YYYY") : "N/A"),
    },
    {
      title: "Action",
      align: "center",
      dataIndex: "alarm",
      width: 100,
      render: (val) => (
        <a download href={val}>
          <Button
            className="accent-text"
            type="text"
            // icon={<EyeIcon />}
          >
            Download
          </Button>
        </a>
      ),
    },
  ];

  useEffect(() => {
    dispatch(towerBulkMailAsync());
  }, [dispatch]);

  return (
    <AdminLayout title="Alarm List" headerTitle="Alarm">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Alarm List"
          //   subTitle={`Total Alarm: ${AddZero(
          //     keyword && !loading ? allSupplier?.length : supplierCount
          //   )}`}
        >
          {/* <Space size="middle" className="flex-wrap">
            
          </Space> */}
        </Subheader>

        <CustomTable<any>
          loading={bulkLoading}
          total={towerBulkMail?.length}
          page={page}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(towerBulkMail)}
          isPagination={false}
        />
      </section>
    </AdminLayout>
  );
}
