export default function TaskComplete() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="6" fill="#E6FFFB" />
      <path
        d="M25.2498 14H16.249C16.0501 14 15.8593 14.079 15.7187 14.2197C15.578 14.3603 15.499 14.5511 15.499 14.75V31.25C15.499 31.4489 15.578 31.6397 15.7187 31.7803C15.8593 31.921 16.0501 32 16.249 32H29.7498C29.8482 32 29.9458 31.9806 30.0368 31.9429C30.1278 31.9052 30.2104 31.85 30.2801 31.7803C30.3497 31.7107 30.405 31.628 30.4427 31.537C30.4804 31.446 30.4998 31.3485 30.4998 31.25V19.25M25.2498 14L30.4998 19.25M25.2498 14V19.25H30.4998M19.9998 26.75H25.9998M19.9998 23.75H25.9998"
        stroke="#27CAB5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
