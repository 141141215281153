export default function PGStopped() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="6" fill="#FFF1F0" />
      <path
        d="M15.5 14.7497L30.5 31.2497M26.3541 26.6892L20 33.4971L21.5 25.9971L15.5 23.7471L19.6436 19.3076M21.6974 17.107L26 12.4971L24.5 19.9971L30.5 22.2471L28.408 24.4886"
        stroke="#FF3838"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
