import mapboxgl from "mapbox-gl";
import { useEffect, useRef } from "react";
import { MAP_TOKEN } from "../../../helpers/config";
import { useAppSelector } from "../../../store/store";

mapboxgl.accessToken = MAP_TOKEN as string;

export default function Map() {
  const { activeInactiveMap } = useAppSelector((state) => state.dashboard);
  const mapContainerRef = useRef<HTMLDivElement>(null);

  const data = activeInactiveMap?.map((el, index) => ({
    id: index,
    lat: el?.latitude || 0,
    lon: el?.longitude || 0,
    radius: 10,
    active:
      el?.status === "finished" || !el?.status
        ? true
        : el?.status
        ? false
        : null,
  }));

  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapContainerRef.current!,
      style: "mapbox://styles/mapbox/streets-v11",
      center: [
        activeInactiveMap?.[0]?.longitude || 90.3621026,
        activeInactiveMap?.[0]?.latitude || 23.7055748,
      ],
      zoom: 8,
    });

    map.on("load", () => {
      data.forEach((point) => {
        map.addSource(`circle-${point.id}`, {
          type: "geojson",
          data: {
            type: "Feature",
            geometry: {
              type: "Point",
              coordinates: [point.lon, point.lat],
            },
            properties: {},
          },
        });

        map.addLayer({
          id: `circle-layer-${point.id}`,
          type: "circle",
          source: `circle-${point.id}`,
          paint: {
            "circle-radius": point.radius || 15,
            "circle-color": point.active
              ? "#33AB29"
              : point.active === false
              ? "#FF3838"
              : "",
            "circle-opacity": 0.8,
          },
        });
      });
    });

    return () => map.remove();
  }, [data]);

  return (
    <div className="my-5">
      <div className="w-full h-[350px] rounded-xl dashboard_map">
        <div
          ref={mapContainerRef}
          style={{ width: "100%", height: "100%", borderRadius: "12px" }}
        />
      </div>
    </div>
  );
}
