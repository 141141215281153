import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { Link } from "react-router-dom";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import { NetworkTableColumn } from "../../../types";
import CustomTable from "../../common/CustomTable";

export default function SAPaymentTable() {
  const { limit, page, handlePagination } = useTable();
  console.log(limit);

  const columns: ColumnsType<NetworkTableColumn> = [
    {
      title: "Operator Information",
      render: (val) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <h5>{val?.name}</h5>
          <p>ID: {val?.ref}</p>
        </Space>
      ),
    },
    {
      title: "Task Duration",
      dataIndex: "contact",
      align: "center",
    },

    {
      title: "Status",
      dataIndex: "division",
      align: "center",
    },
    {
      title: "Actual Duration",
      dataIndex: "operator",
      align: "center",
    },

    {
      title: "Action",
      align: "center",
      render: (val: NetworkTableColumn) =>
        val?.status === "payment" ? (
          <Button type="primary" size="large">
            Payment
          </Button>
        ) : val?.status === "paid" ? (
          <Link className="paid_btn" to="#">
            <Button size="large">Paid</Button>
          </Link>
        ) : null,
    },
  ];

  return (
    <CustomTable<NetworkTableColumn>
      loading={false}
      total={20}
      page={page}
      handlePagination={handlePagination}
      columns={columns}
      dataList={addKeyInArray(data)}
    />
  );
}

const data = [
  {
    _id: "6",
    name: "Md Alamgir Hossain",
    ref: "O-098765",
    contact: "+8801736389503",
    location: "Indoor",
    division: "Dhaka",
    status: "payment",
    operator: 20,
  },
  {
    _id: "5",
    name: "Md. Belal Hossain",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "payment",
    operator: 34,
  },
  {
    _id: "4",
    name: "Md. Rakibul Islam",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 12,
  },
  {
    _id: "3",
    name: "Md. Abu Said",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 6,
  },
  {
    _id: "2",
    name: "Md. Baker Hossain",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 34,
  },
  {
    _id: "22",
    name: "Md. Omar Faruq",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 34,
  },
  {
    _id: "23",
    name: "Md. Abid Hossain",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 43,
  },
  {
    _id: "24",
    name: "Adiba Hossain",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 67,
  },
  {
    _id: "25",
    name: "Abdul kader",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 75,
  },
  {
    _id: "25",
    name: "Tariqul Islam",
    ref: "O-098765",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "paid",
    operator: 75,
  },
];
