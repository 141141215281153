import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray, pathLink } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllAreaAsync,
  getCountAreaAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CommonParams, GetAreaData, TableData } from "../../../types/redux";
import { AddZero, countRow } from "../../../utils/number";

export default function Area() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { areas, loading, areaCount } = useAppSelector((state) => state.npGeo);
  const location = useLocation();
  const path = pathLink(location.pathname, 1);

  const columns: ColumnsType<TableData<GetAreaData>> = [
    {
      title: "No.",
      align: "center",
      width: 120,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Area Name",
      dataIndex: "areaName",
    },
    {
      title: "Region Name",
      render: (val: GetAreaData) => val?.region?.regionName,
    },
    {
      title: "Action",
      align: "center",
      render: (val: GetAreaData) => (
        <Link to={`/${path}/geo-info/area/update-area/${val?._id}`}>
          <Button icon={<EditIcon />} />
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllAreaAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(getCountAreaAsync());
  }, [dispatch]);

  return (
    <AdminLayout title="Area List" headerTitle="Geo Informations">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Area List"
          subTitle={`Total Area: ${AddZero(areaCount)}`}
        >
          <Space size="middle">
            <Link to={`/${path}/geo-info/area/add-area`}>
              <IconButton type="primary" nextIcon={<AddIcon />}>
                Add Area
              </IconButton>
            </Link>
          </Space>
        </Subheader>
      </section>

      <CustomTable<TableData<GetAreaData>>
        loading={loading}
        total={areaCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(areas)}
        bordered={false}
      />
    </AdminLayout>
  );
}
