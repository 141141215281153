import { Fragment } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { pathLink } from "../helpers/siteConfig";
import { useAppSelector } from "../store/store";
import { Role } from "../types";

type Props = {
  children: React.ReactNode;
  roles: Role[];
};

export default function AuthMiddleware({ children, roles }: Props) {
  const { token, siteRole, login } = useAppSelector((state) => state.login);
  const location = useLocation();
  const path = pathLink(location.pathname, 1);

  const accessRole = siteRole && roles?.length && roles?.includes(siteRole);

  return token && login?.email ? (
    accessRole ? (
      <Fragment>{children}</Fragment>
    ) : (
      <Navigate to="/404" replace />
    )
  ) : (
    <Navigate to={`/${path}/login`} replace />
  );
}
