import { Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  createAreaAsync,
  getAllRegionAsync,
  getSingleAreaAsync,
  updateAreaAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { AreaData } from "../../../types/redux";

const navLink = "/sa/geo-info/area";

export default function ManageArea() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectRegion, setSelectRegion] = useState("");
  const { postLoading, regions, loading, singleArea, updateLoading } =
    useAppSelector((state) => state.npGeo);

  const onFinish: FormProps<AreaData>["onFinish"] = (values) => {
    if (id) {
      dispatch(
        updateAreaAsync({
          data: values,
          others: {
            reRender,
            id,
          },
        })
      );
    } else {
      dispatch(
        createAreaAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);

  useEffect(() => {
    if (id && singleArea && updateLoading === false) {
      setSelectRegion(singleArea?.region?._id);
      form.setFieldsValue({
        region: singleArea?.region?._id,
        areaName: singleArea?.areaName,
      });
    }
  }, [form, id, singleArea, updateLoading]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleAreaAsync({ params: { id } }));
    }
  }, [dispatch, id]);

  return (
    <AdminLayout
      title={id ? "Update area" : "Add new area"}
      headerTitle="Geo Informations"
    >
      <CustomForm<AreaData>
        onFinish={onFinish}
        title={id ? "Update area" : "Add new area"}
        formTitle={id ? "Update Area" : "Add Area"}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="region"
          label="Select Region"
          rules={[
            {
              required: true,
              message: "Please select region",
            },
          ]}
        >
          <Select
            placeholder="Select region"
            size="large"
            allowClear
            showSearch
            loading={loading}
            filterOption={selectSearchOption}
            options={ArrayOption(regions, "regionName", "_id")}
            onChange={(val) => {
              setSelectRegion(val);
              form.setFieldValue("areaName", undefined);
            }}
            disabled={id ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="areaName"
          label="Area Name"
          rules={[
            {
              required: true,
              message: "Please enter area name",
            },
          ]}
        >
          <Input
            size="large"
            disabled={!selectRegion}
            placeholder="Enter area name"
          />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
