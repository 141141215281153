import { Button } from "antd";
import OngoingIcon from "../../../assets/icons/OngoingIcon";
import AvatarCard from "../../ui/AvatarCard";
import ServiceDuration from "../../ui/ServiceDuration";
import ServiceHistory, { HistoryListItem } from "../../ui/ServiceHistory";
import NetworkActionModal from "./NetworkActionModal";

type Props = {
  open: boolean;
  handleClose: () => void;
  data?: any;
};

export default function View({ open, handleClose }: Props) {
  // const defaultRatingValue = 1;
  // const maximumRatingValue = 5;
  // const [ratingValue, setRatingValue] = useState(defaultRatingValue);

  return (
    <NetworkActionModal
      title="Site Locator"
      locatorTitle="Shilmoon West Para - Mymensingh"
      iconColor="bg-primary-np"
      open={open}
      handleClose={handleClose}
    >
      <div>
        <ServiceDuration
          icon={<OngoingIcon />}
          statusTitle="Ongoing"
          statusClass="text-status-danger-btn"
          time="02.32"
        />

        <ServiceHistory>
          <HistoryListItem title="Agent ID:">{data?.agentId}</HistoryListItem>
          <HistoryListItem title="Start time:">
            {data?.startTime}
          </HistoryListItem>
          <HistoryListItem title="Off Time:">{data?.offTime}</HistoryListItem>
        </ServiceHistory>

        <div className="rounded-lg border border-indigo-300 border-solid bg-white px-3.5 py-2.5 mb-5 select-none">
          <AvatarCard
            name="Alamgir"
            id="O-56352"
            time="12:35 PM"
            showRating
            rating={4}
            hideBorder
          />
        </div>

        <Button
          className="disable-btn"
          type="default"
          size="large"
          disabled
          block
        >
          Task Close & Ready to Payment
        </Button>
      </div>
    </NetworkActionModal>
  );
}

const data = {
  agentId: "A-098765",
  startTime: "10.40 AM",
  offTime: "12.02 PM",
  serviceCharge: "3,020 BDT",
};
