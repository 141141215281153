import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import EditIcon from "../../../assets/icons/EditIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray, pathLink } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllTerritoryAsync,
  getCountTerritoryAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  CommonParams,
  GetTerritoryData,
  TableData,
} from "../../../types/redux";
import { AddZero, countRow } from "../../../utils/number";

export default function Territory() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { territorys, loading, territoryCount } = useAppSelector(
    (state) => state.npGeo
  );
  const location = useLocation();
  const path = pathLink(location.pathname, 1);

  const columns: ColumnsType<TableData<GetTerritoryData>> = [
    {
      title: "No.",
      align: "center",
      width: 120,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Territory Name",
      dataIndex: "teritoryName",
    },
    {
      title: "Area Name",
      render: (val: GetTerritoryData) => val?.area?.areaName,
    },
    {
      title: "Region Name",
      render: (val: GetTerritoryData) => val?.region?.regionName,
    },
    {
      title: "Action",
      align: "center",
      render: (val: GetTerritoryData) => (
        <Link to={`/${path}/geo-info/territory/update-territory/${val?._id}`}>
          <Button icon={<EditIcon />} />
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllTerritoryAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(getCountTerritoryAsync());
  }, [dispatch]);

  return (
    <AdminLayout title="Territory List" headerTitle="Geo Information">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Territory List"
          subTitle={`Total Territory: ${AddZero(territoryCount)}`}
        >
          <Space size="middle">
            <Link to={`/${path}/geo-info/territory/add-territory`}>
              <IconButton type="primary" nextIcon={<AddIcon />}>
                Add Territory
              </IconButton>
            </Link>
          </Space>
        </Subheader>
      </section>

      <CustomTable<TableData<GetTerritoryData>>
        loading={loading}
        total={territoryCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(territorys)}
        bordered={false}
      />
    </AdminLayout>
  );
}
