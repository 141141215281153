export default function RightUpArrow() {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.25 3.75H19.5C19.6989 3.75 19.8897 3.82902 20.0303 3.96967C20.171 4.11032 20.25 4.30109 20.25 4.5V15.75C20.25 15.9489 20.171 16.1397 20.0303 16.2803C19.8897 16.421 19.6989 16.5 19.5 16.5C19.3011 16.5 19.1103 16.421 18.9697 16.2803C18.829 16.1397 18.75 15.9489 18.75 15.75V6.31L5.03 20.03C4.88782 20.1625 4.69978 20.2346 4.50548 20.2312C4.31118 20.2277 4.12579 20.149 3.98838 20.0116C3.85096 19.8742 3.77225 19.6888 3.76882 19.4945C3.7654 19.3002 3.83752 19.1122 3.97 18.97L17.69 5.25H8.25C8.05109 5.25 7.86032 5.17098 7.71967 5.03033C7.57902 4.88968 7.5 4.69891 7.5 4.5C7.5 4.30109 7.57902 4.11032 7.71967 3.96967C7.86032 3.82902 8.05109 3.75 8.25 3.75Z"
        fill="currentColor"
      />
    </svg>
  );
}
