import { Button, ButtonProps, Form, FormProps, Space } from "antd";
import { HTMLAttributes, ReactNode } from "react";
import { Link } from "react-router-dom";
import LeftArrowIcon from "../../assets/icons/LeftArrowIcon";
import Subheader from "../ui/Subheader";
import IconButton from "./IconButton";

interface Props<T> extends HTMLAttributes<HTMLDivElement> {
  title: string;
  formTitle: string;
  backLink: string;
  submitBtnName?: string;
  disabled?: boolean;
  disabledSubmit?: boolean;
  onFinish: (props: T) => void;
  className?: string;
  children: ReactNode;
  formProps?: FormProps;
  buttonProps?: ButtonProps;
  isUpdate?: boolean;
}

export default function CustomForm<T>({
  title,
  formTitle,
  backLink,
  isUpdate = false,
  submitBtnName = isUpdate ? "Update Now" : "Add Now",
  onFinish,
  className = "",
  children,
  disabled = false,
  disabledSubmit = false,
  formProps,
  buttonProps,
  ...rest
}: Props<T>) {
  return (
    <section
      {...rest}
      className={`shadow-sm bg-white rounded-lg mt-7 pb-7 ${className}`}
    >
      <Subheader title={title}>
        <Space size="middle">
          <Link to={backLink}>
            <IconButton
              className="!border-others-header !text-sm !font-normal !rounded-lg !border !text-header !bg-transparent"
              type="default"
              icon={<LeftArrowIcon />}
            >
              <span>Back</span>
            </IconButton>
          </Link>
        </Space>
      </Subheader>

      <div className="max-w-xl mx-auto px-6 pt-14 min-h-[400px]">
        <h5 className="text-lg font-semibold text-header mb-5">{formTitle}</h5>
        <Form
          className="custom-form hide_star_mark"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          {...formProps}
        >
          {children}
          <div className="w-full justify-between flex gap-4 pt-1">
            <Link
              className="block w-full cancel_btn"
              to={disabled ? "" : backLink}
            >
              <Button disabled={disabled} block size="large">
                Cancel
              </Button>
            </Link>
            <Button
              type="primary"
              disabled={disabledSubmit || disabled}
              size="large"
              htmlType="submit"
              block
              loading={disabled}
              {...buttonProps}
            >
              {submitBtnName}
            </Button>
          </div>
        </Form>
      </div>
    </section>
  );
}
