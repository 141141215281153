import { createSlice } from "@reduxjs/toolkit";
import {
  GetAllUsersData,
  NPResourceInitialStatus,
} from "../../../../types/redux/NP/resource";
import {
  createUserAsync,
  getAllUsersAsync,
  getCountUsersAsync,
} from "./resourceAPI";

const initialState: NPResourceInitialStatus<GetAllUsersData> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  allUsers: [],
  usersCount: 0,
};

const resourceSlice = createSlice({
  name: "np-resource",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create user
    builder
      .addCase(createUserAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createUserAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createUserAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all users
    builder
      .addCase(getAllUsersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allUsers = action.payload;
      })
      .addCase(getAllUsersAsync.rejected, (state) => {
        state.loading = false;
        state.allUsers = [];
      });
    // get users count
    builder
      .addCase(getCountUsersAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountUsersAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.usersCount = action.payload;
      })
      .addCase(getCountUsersAsync.rejected, (state) => {
        state.loading = false;
        state.usersCount = 0;
      });
  },
});

export default resourceSlice;
