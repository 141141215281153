import { HTMLProps, ReactNode } from "react";

type Props = {
  icon: ReactNode;
  statusTitle: string;
  statusClass: string;
  time: string;
  timeFormat?: string;
  className?: string;
  children?: ReactNode;
} & HTMLProps<HTMLDivElement>;

export default function ServiceDuration({
  icon,
  statusTitle,
  statusClass,
  time,
  timeFormat = "min",
  className = "",
  children,
  ...rest
}: Props) {
  return (
    <div className={`text-center mb-4 ${className}`} {...rest}>
      <div className="flex items-center justify-center mb-1.5">{icon}</div>
      <h4 className={`text-lg font-medium ${statusClass}`}>{statusTitle}</h4>
      <h6 className="text-header text-sm font-medium py-1">Service Duration</h6>
      <div className="inline-flex gap-1.5 items-end">
        <h3 className="text-header text-2xl font-semibold">{time}</h3>
        <span className="text-others-gray text-sm font-semibold mb-[3px]">
          {timeFormat}
        </span>
      </div>
      {children}
    </div>
  );
}
