import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import { NetworkTableColumn } from "../../../types";
import CustomTable from "../../common/CustomTable";

export default function SASupportOperatorTable() {
  const { limit, page, handlePagination } = useTable();
  console.log(limit);

  const columns: ColumnsType<NetworkTableColumn> = [
    {
      title: "Agent Information",
      render: (val) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <h5>{val?.name}</h5>
          <p>{val?.ref}</p>
        </Space>
      ),
    },
    {
      title: "Contact No.",
      dataIndex: "contact",
      align: "center",
    },

    {
      title: "Division",
      dataIndex: "division",
      align: "center",
    },
    {
      title: "Total Operator",
      dataIndex: "operator",
      align: "center",
    },

    {
      title: "Action",
      align: "center",
      render: (val: NetworkTableColumn) =>
        val?.status === "Inactive" ? (
          <Button className="btn-status btn-status-done">
            Ticket Generate
          </Button>
        ) : val?.status === "Completed" ? (
          <Button className="btn-status btn-status-done">Feedback</Button>
        ) : val?.status === "Pause" ? (
          <Button className="btn-status btn-status-done">Fix Now</Button>
        ) : val?.status === "Ongoing" ? (
          <Button className="btn-status btn-status-done">View</Button>
        ) : val?.status === "Waiting for payment" ? (
          <Button className="btn-status btn-status-done">Pay Now</Button>
        ) : null,
    },
  ];

  return (
    <CustomTable<NetworkTableColumn>
      loading={false}
      total={20}
      page={page}
      handlePagination={handlePagination}
      columns={columns}
      dataList={addKeyInArray(data)}
    />
  );
}

const data = [
  {
    _id: "6",
    name: "Dhaka Mirpur area",
    ref: "New Elephant Road, Ghanmondi, Hhaka-1205",
    contact: "+8801736389503",
    location: "Indoor",
    division: "Dhaka",
    status: "Inactive",
    operator: 20,
  },
  {
    _id: "5",
    name: "Mymensing",
    ref: "Mymensing shadar",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Waiting for payment",
    operator: 34,
  },
  {
    _id: "4",
    name: "Mymensing",
    ref: "Mymensing shadar, Mymensing",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Completed",
    operator: 12,
  },
  {
    _id: "3",
    name: "Mymensing",
    ref: "Mymensing Sadar, Mymensing",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Pause",
    operator: 6,
  },
  {
    _id: "2",
    name: "Shariatpur",
    ref: "Palang, shariatpur sadar, shariatpur",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Inactive",
    operator: 34,
  },
  {
    _id: "22",
    name: "Savar",
    ref: "Amin bazar, savar, dhaka",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Ongoing",
    operator: 34,
  },
  {
    _id: "23",
    name: "Dhaka Gulshan area",
    ref: "New Elephant Road, Dhanmond, Hhaka-1205",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Completed",
    operator: 43,
  },
  {
    _id: "24",
    name: "Dhaka Shouth",
    ref: "hadar ghat area, Dhaka",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Pause",
    operator: 67,
  },
  {
    _id: "25",
    name: "Narayangonj City",
    ref: "Chashara, Narayanganj",
    contact: "+8801736389503",
    division: "Dhaka",
    status: "Ongoing",
    operator: 75,
  },
];
