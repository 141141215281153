import { ReactNode } from "react";
import LOGO_BOTTOM from "../assets/images/login/logo-bottom.svg";
import LOGO_TOP from "../assets/images/login/logo-top.svg";
import LOGO_IMG from "../assets/images/login/main-logo-white.svg";
import AppLayout from "./AppLayout";

type Props = {
  LoginImage?: string;
  LogoTop?: string;
  LogoBottom?: string;
  title?: string;
  children: ReactNode;
  bgColor?: string;
};

export default function LoginLayout({
  LoginImage = LOGO_IMG,
  LogoTop = LOGO_TOP,
  LogoBottom = LOGO_BOTTOM,
  title = "Welcome back to axs. Please Sign in to continue.",
  children,
  bgColor = "bg-primary-np",
}: Props) {
  return (
    <AppLayout title="Login">
      <div className="h-screen">
        <div className="grid md:grid-cols-2 h-full">
          <div
            className={`hidden relative h-full text-center md:flex items-center justify-center ${bgColor}`}
          >
            <img className="h-52" src={LoginImage} alt="" />
            <img
              className="h-52 absolute top-0 opacity-10"
              src={LogoTop}
              alt=""
            />
            <img
              className="h-52 absolute bottom-0 opacity-10"
              src={LogoBottom}
              alt=""
            />
          </div>

          <div className="p-6 lg:p-20 flex justify-center items-center">
            <div className="mx-auto !max-w-md w-full lg:w-3/4">
              <div className="flex items-center shadow-md p-6 rounded-2xl">
                <div className="w-full">
                  <div className="text-center mb-6">
                    <h3 className="text-2xl font-bold text-header mb-2">
                      Welcome Back!
                    </h3>
                    <p>{title}</p>
                  </div>

                  <div>{children}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
}
