import axios from "axios";
import mapboxgl from "mapbox-gl";
import React, { useEffect } from "react";
import { MAP_TOKEN } from "../../../helpers/config";

mapboxgl.accessToken = MAP_TOKEN as string;

interface Props {
  currentLocation: { lat?: number; lon?: number };
  destination: { lat?: number; lon?: number };
}

const DistanceCalculator: React.FC<Props> = ({
  currentLocation,
  destination,
}) => {
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: "map",
      style: "mapbox://styles/mapbox/streets-v11",
      center: [90.3621026, 23.7055748],
      zoom: 12,
    });

    const fetchRoute = async () => {
      try {
        const response = await axios.get(
          `https://api.mapbox.com/directions/v5/mapbox/driving/${currentLocation.lon},${currentLocation.lat};${destination.lon},${destination.lat}`,
          {
            params: {
              access_token: mapboxgl.accessToken,
              geometries: "geojson",
            },
          }
        );

        const data = response?.data;
        const route = data?.routes[0];

        map.on("load", () => {
          // const bounds = new mapboxgl.LngLatBounds();
          // bounds.extend([currentLocation.lon || 0, currentLocation.lat || 0]);
          // bounds.extend([destination.lon || 0, destination.lat || 0]);
          // map.fitBounds(bounds, { padding: 50 as any });

          map.setCenter([
            ((currentLocation?.lon || 0) + (destination?.lon || 0)) / 2,
            ((currentLocation?.lat || 0) + (destination?.lat || 0)) / 2,
          ]);

          map.addSource("route", {
            type: "geojson",
            data: {
              type: "Feature",
              geometry: route.geometry,
            } as any,
          });

          map.addLayer({
            id: "route",
            type: "line",
            source: "route",
            layout: {
              "line-join": "round",
              "line-cap": "round",
            },
            paint: {
              "line-color": "#33AB29",
              "line-width": 4,
            },
          });

          // current location
          map.addSource(
            `circle-${currentLocation.lat || 0}-${currentLocation.lon || 0}`,
            {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [
                    currentLocation.lon || 0,
                    currentLocation.lat || 0,
                  ],
                },
                properties: {},
              },
            }
          );

          map.addLayer({
            id: `circle-layer-${currentLocation.lat || 0}-${
              currentLocation.lon || 0
            }`,
            type: "circle",
            source: `circle-${currentLocation.lat || 0}-${
              currentLocation.lon || 0
            }`,
            paint: {
              "circle-radius": 6,
              "circle-color": "#33AB29",
              "circle-opacity": 1,
            },
          });

          // destination
          map.addSource(
            `circle-${destination.lat || 0}-${destination.lon || 0}`,
            {
              type: "geojson",
              data: {
                type: "Feature",
                geometry: {
                  type: "Point",
                  coordinates: [destination.lon || 0, destination.lat || 0],
                },
                properties: {},
              },
            }
          );

          map.addLayer({
            id: `circle-layer-${destination.lat || 0}-${destination.lon || 0}`,
            type: "circle",
            source: `circle-${destination.lat || 0}-${destination.lon || 0}`,
            paint: {
              "circle-radius": 6,
              "circle-color": "#FF3838",
              "circle-opacity": 1,
            },
          });
        });
      } catch (err) {}
    };

    fetchRoute();

    return () => map.remove();
  }, [currentLocation, destination]);

  return (
    <div className="mt-[66px]">
      <div id="map" style={{ width: "100%", height: "400px" }}></div>
    </div>
  );
};

export default DistanceCalculator;
