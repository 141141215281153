export default function OngoingIcon() {
  return (
    <svg
      width="34"
      height="34"
      viewBox="0 0 34 34"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_1245_3568)">
        <path
          d="M0.509521 32.7741H7.74564L9.67873 30.841H24.331L26.2638 32.7741H33.5V26.9751H0.509521V32.7741Z"
          fill="#FC8415"
        />
        <path
          d="M17.0048 11.5109C14.3373 11.5109 12.1724 13.6758 12.1724 16.3433C12.1724 19.0108 14.3373 21.1758 17.0048 21.1758C19.6723 21.1758 21.8376 19.0108 21.8376 16.3433C21.8376 13.6758 19.6723 11.5109 17.0048 11.5109ZM17.9132 19.5521L16.0965 18.9335L16.6375 17.3099H14.7047L16.0965 13.1345L17.9132 13.7531L17.3722 15.3768H19.3053L17.9132 19.5521Z"
          fill="#FC8415"
        />
        <path
          d="M0.509521 7.64478V25.0421H33.5V7.64478H0.509521ZM17.0047 23.109C13.274 23.109 10.2392 20.0742 10.2392 16.3434C10.2392 12.6127 13.274 9.57787 17.0047 9.57787C20.7355 9.57787 23.7703 12.6127 23.7703 16.3434C23.7703 20.0742 20.7355 23.109 17.0047 23.109Z"
          fill="#FC8415"
        />
        <path
          d="M2.5072 5.71163H10.2393V3.77879H12.1724V1.8457C11.8914 1.8457 0.11589 1.8457 0.509659 1.8457V3.77879H2.5072V5.71163Z"
          fill="#FC8415"
        />
      </g>
      <defs>
        <clipPath id="clip0_1245_3568">
          <rect
            width="33"
            height="32.9356"
            fill="white"
            transform="translate(0.5 0.782227)"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
