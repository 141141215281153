import { Avatar, Rate, Space } from "antd";
import { firstChar } from "../../utils/string";

type Props = {
  avatar?: string;
  status?: string;
  name: string;
  email?: string;
  id?: string;
  time?: string;
  showRating?: boolean;
  hideBorder?: boolean;
  rating?: number;
  ratingValue?: number;
};

export default function AvatarCard({
  avatar,
  name,
  status,
  email,
  id,
  time,
  ratingValue = 0,
  showRating = false,
  hideBorder = false,
  rating = 0,
}: Props) {
  return (
    <Space
      className={`w-full ${
        !hideBorder
          ? "pb-4 border-b border-t-0 border-x-0 border-dashed border-zinc-200"
          : ""
      }`}
      size={"middle"}
    >
      <Avatar src={avatar} size={50}>
        {!avatar && firstChar(name)}
      </Avatar>
      <div>
        <h3 className="text-header font-medium text-base">{name}</h3>
        <p className="text-sm font-medium">
          {id ? (
            <span
              className={
                status === "Complete"
                  ? "text-status-info-text"
                  : "text-status-waiting-text"
              }
            >
              ID: {id}
            </span>
          ) : null}
          {email ? <div className="text-accent">Email: {email}</div> : null}
          {time ? (
            <>
              <span className="px-2">|</span> {time}
            </>
          ) : null}
        </p>

        {showRating ? (
          <div className="flex items-center gap-2 custom_rating">
            <Rate disabled defaultValue={ratingValue} allowHalf />
            <span className="text-body text-xs font-medium mt-1">
              ({rating} {rating === 0 || rating === 1 ? "Rating" : "Ratings"})
            </span>
          </div>
        ) : null}
      </div>
    </Space>
  );
}
