import { Select, Space } from "antd";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import SASupportOperatorTable from "../../../components/SA/SupportAgent/SASupportOperatorTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";

export default function SupportOperator() {
  const handleSiteLocator = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <AdminLayout
      title="Dhaka Division Support Agents"
      headerTitle="Support Agents"
      headerChildren={
        <Link to="#">
          <IconButton type="primary" icon={<AddIcon />}>
            Request for new Agent
          </IconButton>
        </Link>
      }
    >
      <section className="shadow-sm mt-7">
        <Subheader title="Dhaka Division Support Agents">
          <Space size="middle">
            <Select
              // showSearch
              placeholder="Dhaka Division"
              optionFilterProp="children"
              onChange={handleSiteLocator}
              filterOption={selectSearchOption}
              // defaultValue="Inactive Networks"
              className="min-w-[8rem]"
              options={[
                {
                  value: "Dhaka Division",
                  label: "Dhaka Division",
                },
                {
                  value: "Pending",
                  label: "Pending",
                },
              ]}
            />
          </Space>
        </Subheader>

        <SASupportOperatorTable />
      </section>
    </AdminLayout>
  );
}
