import {
  Button,
  DatePicker,
  Form,
  FormProps,
  Input,
  InputNumber,
  TimePicker,
} from "antd";
import moment from "moment";
import { useEffect } from "react";
import { getAllRegionAsync } from "../../../store/features/NP/geoInfo/geoAPI";
import {
  createTowerTicketAsync,
  towerTicketNotificationAsync,
} from "../../../store/features/NP/network/networkAPI";
import { getAllUsersAsync } from "../../../store/features/NP/resource/resourceAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  GetAllTowerData,
  GetAllUsersParams,
  TicketGenerateData,
} from "../../../types/redux";
import { capitalize } from "../../../utils/string";
import { formatDate } from "../../../utils/time";
import LocatorListItem from "../../ui/LocatorListItem";
import NetworkActionModal from "./NetworkActionModal";

type Props = {
  open: boolean;
  handleClose: () => void;
  data?: any;
  reRender: (val: boolean) => void;
};

export default function TicketGenerate({
  open,
  handleClose,
  data,
  reRender,
}: Props) {
  const dispatch = useAppDispatch();
  // const [selectRegion, setSelectRegion] = useState("");
  // const [selectArea, setSelectArea] = useState("");
  // const [selectTerritory, setSelectTerritory] = useState("");
  // const { regions, areas, territorys } = useAppSelector((state) => state.npGeo);
  const { allUsers } = useAppSelector((state) => state.resource);
  const { postLoading } = useAppSelector((state) => state.network);
  const ticketData = data as GetAllTowerData;
  // console.log(ticketData);

  const onFinish: FormProps<TicketGenerateData>["onFinish"] = (values) => {
    const {
      checkList,
      longitude,
      latitude,
      contactNo,
      towerName,
      _id,
      siteCode,
      region,
      regionName,
      area,
      areaName,
      teritory,
      teritoryName,
      optionalContactNo,
      address,
      radius,
      kpi,
      contactPersonName,
    } = ticketData;
    const { offTime, date, ...rest } = values;
    const time = offTime as any;
    const selectDate = date as any;

    const data = {
      ...rest,
      region,
      area,
      teritory,
      regionName,
      areaName,
      teritoryName,
      checkList,
      longitude,
      latitude,
      contactNo,
      towerName,
      siteCode,
      tower: _id,
      offTime: time.format("hh:mm A"),
      date: formatDate(selectDate?.format(), "DD/MM/YYYY"),
      requestList: allUsers?.map((el) => ({
        user: el?._id,
      })),
      optionalContactNo,
      address,
      radius,
      kpi,
      contactPersonName,
    };

    dispatch(
      createTowerTicketAsync({
        data,
        others: {
          reRender,
          successDone,
        },
      })
    );
  };

  function successDone(id: string) {
    dispatch(
      towerTicketNotificationAsync({
        data: { towerTicketId: id },
        others: null,
      })
    );
  }

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
  }, [dispatch]);

  // useEffect(() => {
  //   dispatch(
  //     getAllAreaAsync({
  //       params: {
  //         region: selectRegion,
  //       },
  //     })
  //   );
  // }, [dispatch, selectRegion]);

  // useEffect(() => {
  //   dispatch(
  //     getAllTerritoryAsync({
  //       params: {
  //         area: selectArea,
  //       },
  //     })
  //   );
  // }, [dispatch, selectArea]);

  useEffect(() => {
    if (ticketData?.teritory) {
      const params: GetAllUsersParams = {
        teritory: `["${ticketData?.teritory}"]`,
        isPG: true,
      };

      dispatch(
        getAllUsersAsync({
          params,
        })
      );
    }
  }, [dispatch, ticketData?.teritory]);

  return (
    <NetworkActionModal
      title="Site Locator"
      locatorTitle={`${capitalize(ticketData?.towerName)} - ${
        ticketData?.regionName
      }`}
      open={open}
      handleClose={handleClose}
      locatorChildren={
        <>
          <LocatorListItem title="Site Code:">
            {ticketData?.siteCode}
          </LocatorListItem>
          {/* <LocatorListItem title="Structure Type:"></LocatorListItem> */}
          <LocatorListItem title="Location:">
            {capitalize(ticketData?.towerName)}
          </LocatorListItem>
          <LocatorListItem title="Region:">
            {ticketData?.regionName}
          </LocatorListItem>
          <LocatorListItem title="Area:">
            {ticketData?.areaName}
          </LocatorListItem>
          <LocatorListItem title="Territory:">
            {ticketData?.teritoryName}
          </LocatorListItem>
          {/* <LocatorListItem title="Inactive Time:">
            {"N/A"}
            <br />
            {"N/A"}
          </LocatorListItem>
          <LocatorListItem title="Electricity Status:">
            {"N/A"}
            <br />
            {"N/A"}
          </LocatorListItem> */}
        </>
      }
    >
      <div>
        <h2 className="text-center text-header text-xl font-semibold mb-6">
          Request for an agent
        </h2>

        <Form
          className="custom-form hide_star_mark highlight_placeholder"
          layout="vertical"
          onFinish={onFinish}
          size="large"
          initialValues={{
            region: ticketData?.regionName,
            area: ticketData?.areaName,
            teritory: ticketData?.teritoryName,
          }}
        >
          <Form.Item
            name="region"
            label="Select Region"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select region",
            //   },
            // ]}
          >
            <Input disabled size="large" />
            {/* <Select
              placeholder="Select region"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(regions, "regionName", "_id")}
              onChange={(val) => setSelectRegion(val)}
            /> */}
          </Form.Item>
          <Form.Item
            name="area"
            label="Select Area"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select area",
            //   },
            // ]}
          >
            <Input disabled size="large" />
            {/* <Select
              placeholder="Select area"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(areas, "areaName", "_id")}
              onChange={(val) => setSelectArea(val)}
            /> */}
          </Form.Item>
          <Form.Item
            name="teritory"
            label="Select Territory"
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select territory",
            //   },
            // ]}
          >
            <Input disabled size="large" />
            {/* <Select
              placeholder="Select territory"
              size="large"
              allowClear
              showSearch
              filterOption={selectSearchOption}
              options={ArrayOption(territorys, "teritoryName", "_id")}
              onChange={(val) => setSelectTerritory(val)}
            /> */}
          </Form.Item>
          <Form.Item
            name="perMinuteCharge"
            label="Per minute charge"
            rules={[
              {
                required: true,
                message: "Please enter amount",
              },
              {
                type: "number",
                min: 0,
                message: "Amount can't be negative",
              },
            ]}
          >
            <InputNumber
              className="w-full"
              size="large"
              placeholder="Enter amount"
            />
          </Form.Item>
          <Form.Item
            name="date"
            label="Select Date"
            rules={[
              {
                required: true,
                message: "Please select date",
              },
            ]}
          >
            <DatePicker
              size="large"
              className="w-full"
              disabledDate={(current) =>
                current.isBefore(moment().subtract(1, "day") as any)
              }
            />
          </Form.Item>
          <Form.Item
            name="offTime"
            label="Select Time"
            rules={[
              {
                required: true,
                message: "Please select time",
              },
            ]}
          >
            <TimePicker
              size="large"
              className="w-full"
              placeholder="HH:MM"
              format={"HH:mm"}
            />
          </Form.Item>

          <Button
            className="mt-2"
            type="primary"
            size="large"
            htmlType="submit"
            block
            loading={postLoading}
          >
            Next
          </Button>
        </Form>
      </div>
    </NetworkActionModal>
  );
}
