import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { AUTH_SAVE } from "../../../helpers/config";
import { toast } from "../../../helpers/toast";
import {
  AuthUser,
  CommonOthers,
  ErrorMessage,
  LoginActionType,
  LoginData,
  PostRequest,
} from "../../../types/redux";

// login user
export const loginUserAsync = createAsyncThunk<
  LoginActionType<AuthUser>,
  PostRequest<LoginData, CommonOthers>,
  {
    rejectValue: ErrorMessage;
  }
>(
  "login/npLogin",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      const loginData = await AXIOS.post(BASE_API.login, data);

      const responseData = {
        token: loginData?.data?.token,
        data: loginData?.data?.UserLogin?.data,
        // TODO: default NP
        siteRole: data?.loginRole,
        // siteRole: "SA",
      };

      if (responseData?.token && responseData?.data?.email) {
        AXIOS.defaults.headers.common[
          "Authorization"
        ] = `Bearer ${responseData?.token}`;

        localStorage.setItem(`${AUTH_SAVE}`, JSON.stringify(responseData));
      }

      reRender(true);
      toast("success", "Login successfully");

      return responseData as LoginActionType<AuthUser>;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "Login fail");
      return rejectWithValue(error);
    }
  }
);

// forgot password user
// export const forgotPasswordAsync = createAsyncThunk<
//   AuthUser,
//   PostRequest<any, CommonOthers>,
//   {
//     rejectValue: ErrorMessage;
//   }
// >(
//   "login/forgotPassword",
//   async ({ data, others: { reRender } }, { rejectWithValue }) => {
//     try {
//       const loginData = await AXIOS.post(BASE_API.forgotPassword, data);

//       if (loginData?.data?.token && loginData?.data?.email) {
//         AXIOS.defaults.headers.common[
//           "Authorization"
//         ] = `Bearer ${loginData?.data?.token}`;

//         localStorage.setItem(`${AUTH_SAVE}`, JSON.stringify(loginData?.data));

//         reRender(true);
//       } else if (loginData) {
//         reRender(false);
//       }

//       return loginData.data as AuthUser;
//     } catch (err) {
//       const errors = err as AxiosError;
//       const error = errors.response?.data as ErrorMessage;
//       return rejectWithValue(error);
//     }
//   }
// );
