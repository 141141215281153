import { CloseOutlined } from "@ant-design/icons";
import { Modal } from "antd";
import { CustomModalProps } from "../../types";

export default function CustomModal({
  title,
  open,
  centered = true,
  disableCloseBtn = false,
  handleClose,
  children,
  className = "",
  ...rest
}: CustomModalProps) {
  return (
    <Modal
      title={title}
      centered={centered}
      className={`custom-modal ${className}`}
      open={open}
      onCancel={() => !disableCloseBtn && handleClose()}
      footer={null}
      closeIcon={
        <div className="flex items-center gap-2 bg-white hover:bg-white/90 h-8 text-status-danger-text font-medium text-sm px-2 rounded-lg border-none">
          Close
          <CloseOutlined />
        </div>
      }
      {...rest}
    >
      {children}
    </Modal>
  );
}
