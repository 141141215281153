export default function CompleteIcon() {
  return (
    <svg
      width="50"
      height="50"
      viewBox="0 0 50 50"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.6875 25C4.6875 13.7812 13.7812 4.6875 25 4.6875C36.2188 4.6875 45.3125 13.7812 45.3125 25C45.3125 36.2188 36.2188 45.3125 25 45.3125C13.7812 45.3125 4.6875 36.2188 4.6875 25ZM32.5208 21.2208C32.6458 21.0543 32.7363 20.8644 32.7868 20.6624C32.8374 20.4604 32.8471 20.2503 32.8153 20.0445C32.7835 19.8387 32.7109 19.6413 32.6018 19.464C32.4926 19.2866 32.3491 19.1329 32.1797 19.0118C32.0103 18.8907 31.8184 18.8047 31.6152 18.7588C31.4121 18.7129 31.2018 18.7082 30.9968 18.7447C30.7918 18.7813 30.5962 18.8584 30.4214 18.9717C30.2467 19.0849 30.0963 19.232 29.9792 19.4042L23.2375 28.8417L19.8542 25.4583C19.558 25.1823 19.1662 25.0321 18.7614 25.0392C18.3566 25.0464 17.9704 25.2103 17.6841 25.4966C17.3978 25.7829 17.2339 26.1691 17.2267 26.5739C17.2196 26.9787 17.3698 27.3705 17.6458 27.6667L22.3333 32.3542C22.4937 32.5144 22.6871 32.6379 22.9 32.7159C23.1129 32.794 23.3402 32.8247 23.5662 32.8061C23.7922 32.7875 24.0114 32.7199 24.2086 32.608C24.4058 32.4962 24.5764 32.3427 24.7083 32.1583L32.5208 21.2208Z"
        fill="#11A75C"
      />
    </svg>
  );
}
