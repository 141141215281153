import { useState } from "react";

export default function useTable(pageNumber = 1, limitCount = 10) {
  const [page, setPage] = useState(pageNumber);
  const [limit, setLimit] = useState(limitCount);
  const [isFirstPage, setIsFirstPage] = useState(false);

  const handlePagination = (
    paginationPage: number,
    paginationLimit: number
  ) => {
    setPage(paginationPage);
    setLimit(paginationLimit);
  };

  const handleSetFirstPage = (val: boolean) => {
    setIsFirstPage(val);
  };

  return {
    page,
    limit,
    isFirstPage,
    handlePagination,
    handleSetFirstPage,
  };
}
