import { Button, Select, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import EyeIcon from "../../../assets/icons/EyeIcon";
import { addKeyInArray, selectSearchOption } from "../../../helpers/siteConfig";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import {
  getAllCountTowerAsync2,
  getAllTowerAsync,
  getAllTowerAsync2,
  getCountTowerAsync,
} from "../../../store/features/NP/network/networkAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import {
  GetAllTowerData,
  GetAllTowerParams,
  TableData,
} from "../../../types/redux";
import { stringToArray } from "../../../utils/array";
import { AddZero } from "../../../utils/number";
import { capitalize } from "../../../utils/string";
import CustomTable from "../../common/CustomTable";
import Subheader from "../../ui/Subheader";
import ViewLog from "../Network/ViewLog";

type Props = {
  refresh: boolean;
  removeRefresh: () => void;
};

export default function SiteLocator({ refresh, removeRefresh }: Props) {
  const location = useLocation();
  const [status, setStatus] = useState("");
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination, isFirstPage, handleSetFirstPage } =
    useTable(1, 5);
  const { open, toggle, handleOpen } = useModal();
  const { loading, allTowers, towersCount } = useAppSelector(
    (state) => state.network
  );
  const path = stringToArray(location.pathname, "/")?.[0];
  const pgStatus = status ? stringToArray(status, "-") : "";

  const handleRequestChange = (value: string) => {
    setStatus(value);
    handleSetFirstPage(true);
  };

  const columns: ColumnsType<TableData<GetAllTowerData>> = [
    {
      title: "Site Locator Name",
      render: (val: GetAllTowerData) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <h5>{capitalize(val?.towerName)}</h5>
          <p>Site Code: {val?.siteCode}</p>
        </Space>
      ),
    },
    {
      title: "Region",
      dataIndex: "regionName",
      align: "center",
    },
    {
      title: "Area",
      dataIndex: "areaName",
      align: "center",
    },
    {
      title: "Territory",
      dataIndex: "teritoryName",
      align: "center",
    },
    {
      title: "PG Status",
      align: "center",
      render: (val: GetAllTowerData) => (
        <span
          className={`status ${
            val?.towerTicketLogData?.status === "pending"
              ? "status-pending"
              : val?.towerTicketLogData?.status === "finished"
              ? "status-done"
              : val?.towerTicketLogData?.status === "start"
              ? "status-info"
              : val?.towerTicketLogData?.status === "reached"
              ? "status-waiting"
              : null
          }`}
        >
          {val?.towerTicketLogData?.status
            ? capitalize(val?.towerTicketLogData?.status)
            : "N/A"}
        </span>
      ),
    },

    {
      title: "Action",
      align: "center",
      render: (val: GetAllTowerData) => (
        <Button
          onClick={() =>
            handleOpen({
              type: "LOG",
              data: val,
            })
          }
          className="accent-text"
          disabled={!val?.towerTicketLogData?._id ? true : false}
          type="text"
          icon={<EyeIcon />}
        />
      ),
    },
  ];

  const networkData = useCallback(() => {
    const params: GetAllTowerParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    const filterData =
      status === "unrespond"
        ? { unrespond: true }
        : { filter: status ? [status] : [] };

    dispatch(
      getAllTowerAsync({
        data: {
          ...filterData,
        },
        others: params,
      })
    );
  }, [dispatch, limit, page, status]);

  const networkData2 = useCallback(() => {
    const params: any = {
      limit: limit,
      skip: limit * (page - 1),
    };

    if (pgStatus?.[1]) {
      params.pg = pgStatus?.[1];
    }

    dispatch(
      getAllTowerAsync2({
        params,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, limit, page, pgStatus?.[1]]);

  const getCountTowerData = useCallback(() => {
    const filterData =
      status === "unrespond"
        ? { unrespond: true }
        : { filter: status ? [status] : [] };

    dispatch(
      getCountTowerAsync({
        data: {
          ...filterData,
        },
        others: {},
      })
    );
  }, [dispatch, status]);

  const getCountTower2 = useCallback(() => {
    const params: any = {
      limit: limit,
      skip: limit * (page - 1),
    };

    if (pgStatus?.[1]) {
      params.pg = pgStatus?.[1];
    }

    dispatch(
      getAllCountTowerAsync2({
        params,
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, limit, page, pgStatus?.[1]]);

  useEffect(() => {
    if (pgStatus?.[0] === "pg") {
      networkData2();
      getCountTower2();
    } else {
      networkData();
      getCountTowerData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [networkData, getCountTowerData, getCountTower2, pgStatus?.[0]]);

  useEffect(() => {
    if (status && isFirstPage) {
      handlePagination(1, 5);
      handleSetFirstPage(false);
    }
  }, [handlePagination, handleSetFirstPage, isFirstPage, status]);

  useEffect(() => {
    if (refresh) {
      setStatus("");
      networkData();
      getCountTowerData();
      handlePagination(1, 5);
      removeRefresh();
    }
  }, [
    dispatch,
    getCountTowerData,
    handlePagination,
    networkData,
    refresh,
    removeRefresh,
  ]);

  return (
    <section>
      {open.type === "LOG" && (
        <ViewLog
          title="Site Locator Log"
          locatorTitle="Shilmoon West Para - Mymensingh"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
        />
      )}

      <Subheader
        title="Site Locator"
        subTitle={`Total Network: ${AddZero(towersCount)}`}
      >
        <Space size={"middle"}>
          <Select
            showSearch
            placeholder="Select"
            optionFilterProp="children"
            onChange={handleRequestChange}
            filterOption={selectSearchOption}
            defaultValue={"All"}
            value={status || "All"}
            allowClear={status ? true : false}
            onClear={() => {
              handlePagination(1, 5);
              handleSetFirstPage(false);
              setStatus("");
            }}
            className="min-w-[10rem]"
            options={[
              {
                value: "pending",
                label: "Did not start",
              },
              {
                value: "start",
                label: "Journey Started",
              },
              {
                value: "reached",
                label: "Reached Location",
              },
              {
                value: "finished",
                label: "Task Solved",
              },
              {
                value: "unrespond",
                label: "Unrespond",
              },
              {
                value: "pg-pending",
                label: "PG yet to run",
              },
              {
                value: "pg-start",
                label: "PG Running",
              },
              {
                value: "pg-stop",
                label: "PG Stopped",
              },
            ]}
          />
          <Link to={`/${path}/networks`}>
            <Button type="text" className="secondary-btn">
              See all
            </Button>
          </Link>
        </Space>
      </Subheader>

      <CustomTable<TableData<GetAllTowerData>>
        loading={loading}
        total={towersCount}
        limit={limit}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allTowers)}
      />
    </section>
  );
}
