import { ReactNode } from "react";
import NetworkIcon from "../../../assets/icons/NetworkIcon";
import { CustomModalProps } from "../../../types";
import CustomModal from "../../common/CustomModal";
import LocatorListItem from "../../ui/LocatorListItem";

type Props = {
  iconColor?: "bg-status-waiting-btn" | "bg-primary-np";
  locatorTitle: string;
  locatorChildren?: ReactNode;
  children: ReactNode;
} & CustomModalProps;

export default function NetworkActionModal({
  iconColor = "bg-status-waiting-btn",
  locatorTitle,
  locatorChildren,
  children,
  ...rest
}: Props) {
  return (
    <CustomModal width={880} {...rest}>
      <div className="grid md:grid-cols-2 items-center gap-2.5">
        <div className="p-10 bg-gray-50 rounded-bl-2xl">
          <div className="flex justify-center items-center">
            <span
              className={`inline-flex justify-center items-center w-[54.56px] h-[54.56px] text-white rounded-full text-lg ${iconColor}`}
            >
              <NetworkIcon width="28" height="28" />
            </span>
          </div>

          <h3 className="text-center text-header text-lg font-medium py-3">
            {locatorTitle}
          </h3>

          <ul className="rounded border border-zinc-200 border-solid bg-white">
            {locatorChildren || (
              <>
                <LocatorListItem title="Ref:">{data?.ref}</LocatorListItem>
                <LocatorListItem title="Structure Type:">
                  {data?.type}
                </LocatorListItem>
                <LocatorListItem title="Location:">
                  {data?.location}
                </LocatorListItem>
                <LocatorListItem title="Division:">
                  {data?.division}
                </LocatorListItem>
                <LocatorListItem title="District:">
                  {data?.district}
                </LocatorListItem>
                <LocatorListItem title="Police Station:">
                  {data?.policeStation}
                </LocatorListItem>
                <LocatorListItem title="Post Office:">
                  {data?.postOffice}
                </LocatorListItem>
                <LocatorListItem title="Inactive Time:">
                  {data?.inactive?.date || "N/A"}
                  <br />
                  {data?.inactive?.time || "N/A"}
                </LocatorListItem>
                <LocatorListItem title="Electricity Status:">
                  {data?.electricityStatus?.time1 || "N/A"}
                  <br />
                  {data?.electricityStatus?.time2 || "N/A"}
                </LocatorListItem>
              </>
            )}
          </ul>
        </div>

        <div className="px-10 mt-10 pb-6 rounded-br-2xl">{children}</div>
      </div>
    </CustomModal>
  );
}

const data = {
  ref: "e.coBD028551GP",
  type: "RTT",
  location: "Outdoor",
  division: "Dhaka",
  district: "Mymensingh",
  policeStation: "Muktagacha",
  postOffice: "Muktagacha UPO",
  inactive: {
    date: "04 Jan, 2024",
    time: "10.37 AM",
  },
  electricityStatus: {
    time1: "Off: 10.30 AM",
    time2: "",
  },
};
