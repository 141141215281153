import { Image } from "antd";
import { IMAGE_URL } from "../../../helpers/config";
import { CustomModalProps } from "../../../types";
import CustomModal from "../../common/CustomModal";

type Props = {
  data?: any;
} & CustomModalProps;

export default function ViewImages({ handleClose, data, ...rest }: Props) {
  return (
    <CustomModal width={800} handleClose={handleClose} {...rest}>
      <div
        className={`px-5 pb-5 pt-2 ${
          data?.entryImages?.length === 1 && data?.existImages?.length === 1
            ? "grid grid-cols-2 gap-4"
            : ""
        }`}
      >
        {data?.entryImages?.length ? (
          <div>
            <h3 className="text-header mb-1 font-semibold">Entry Images:</h3>
            <div
              className={`grid gap-4 ${
                data?.entryImages?.length !== 1 ? "md:grid-cols-2" : ""
              }`}
            >
              {data?.entryImages?.map((el: string, idx: number) => (
                <div className="w-full !h-60 shadow" key={idx}>
                  <Image
                    rootClassName="!w-full"
                    className="!max-w-full !w-full !h-60 !object-cover"
                    src={IMAGE_URL + el}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}

        {data?.existImages?.length ? (
          <div>
            <h3
              className={`text-header mb-1 font-semibold ${
                data?.existImages?.length !== 1 ? "mt-2.5" : ""
              }`}
            >
              Exist Images:
            </h3>
            <div
              className={`grid gap-4 ${
                data?.existImages?.length !== 1 ? "md:grid-cols-2" : ""
              }`}
            >
              {data?.existImages?.map((el: string, idx: number) => (
                <div className="w-full !h-60 shadow" key={idx}>
                  <Image
                    rootClassName="!w-full"
                    className="!max-w-full !w-full !h-60 !object-cover"
                    src={IMAGE_URL + el}
                    alt=""
                  />
                </div>
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </CustomModal>
  );
}
