export default function SupportAgentsIcon() {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.712 4.33001C17.3117 4.69941 17.8662 5.13772 18.364 5.63602C18.874 6.14602 19.308 6.70002 19.67 7.28802M16.712 4.33001L13.264 8.46802M16.712 4.33001C15.2941 3.46056 13.6633 3.00037 12 3.00037C10.3368 3.00037 8.70593 3.46056 7.28802 4.33001M19.67 7.28802L15.532 10.736M19.67 7.28802C20.5395 8.70593 20.9997 10.3368 20.9997 12C20.9997 13.6633 20.5395 15.2941 19.67 16.712M13.264 8.46802C13.7862 8.65472 14.2604 8.95532 14.652 9.34801C15.0447 9.73963 15.3453 10.2138 15.532 10.736M13.264 8.46802C12.4465 8.17666 11.5535 8.17666 10.736 8.46802M7.28802 4.33001L10.736 8.46802M7.28802 4.33001C6.68827 4.69938 6.13382 5.1377 5.63602 5.63602C5.13771 6.13383 4.69939 6.68827 4.33001 7.28802M15.532 10.736C15.8234 11.5535 15.8234 12.4465 15.532 13.264M19.67 16.712L15.532 13.264M19.67 16.712C19.3006 17.3117 18.8623 17.8662 18.364 18.364C17.854 18.874 17.3 19.308 16.712 19.67M15.532 13.264C15.351 13.77 15.057 14.246 14.652 14.652C14.2604 15.0447 13.7863 15.3453 13.264 15.532M10.736 8.46802C10.2138 8.65469 9.73963 8.95529 9.34801 9.34801C8.94301 9.75401 8.64902 10.23 8.46802 10.736M13.264 15.532L16.712 19.67M13.264 15.532C12.4465 15.8234 11.5535 15.8234 10.736 15.532M16.712 19.67C15.2941 20.5395 13.6633 20.9997 12 20.9997C10.3368 20.9997 8.70593 20.5395 7.28802 19.67M7.28802 19.67L10.736 15.532M7.28802 19.67C6.68827 19.3006 6.13382 18.8623 5.63602 18.364C5.13772 17.8662 4.69941 17.3117 4.33001 16.712M10.736 15.532C10.2138 15.3453 9.73963 15.0447 9.34801 14.652C8.95532 14.2604 8.65472 13.7862 8.46802 13.264M8.46802 13.264L4.33001 16.712M8.46802 13.264C8.17666 12.4465 8.17666 11.5535 8.46802 10.736M4.33001 16.712C3.46056 15.2941 3.00037 13.6633 3.00037 12C3.00037 10.3368 3.46056 8.70593 4.33001 7.28802M4.33001 7.28802L8.46802 10.736"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
