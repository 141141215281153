import { DatePicker, DatePickerProps, Select, Space } from "antd";

import SATaskTable from "../../../components/SA/Task/SATaskTable";
import Subheader from "../../../components/ui/Subheader";
import { selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";

export default function SATask() {
  const handleSiteLocator = (value: string) => {
    console.log(`selected ${value}`);
  };

  const handleDateChange: DatePickerProps["onChange"] = (date, dateString) => {
    console.log("I am from task page: ", date, dateString);
  };

  return (
    <AdminLayout title="Task Details" headerTitle="Task List">
      <section className="shadow-sm mt-7">
        <Subheader title="Task Details">
          <Space size="middle">
            <Select
              showSearch
              placeholder="Select a person"
              optionFilterProp="children"
              onChange={handleSiteLocator}
              filterOption={selectSearchOption}
              defaultValue="Inactive Networks"
              className="min-w-[8rem]"
              options={[
                {
                  value: "Inactive Networks",
                  label: "Inactive Networks",
                },
                {
                  value: "Pending",
                  label: "Pending",
                },
              ]}
            />

            <DatePicker onChange={handleDateChange} />
          </Space>
        </Subheader>

        <SATaskTable />
      </section>
    </AdminLayout>
  );
}
