import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  CommonOthers,
  ErrorMessage,
  GetAllUsersData,
  GetAllUsersParams,
  GetUsersCountParams,
  Params,
  PostRequest,
  UserData,
} from "../../../../types/redux";

// create a user
export const createUserAsync = createAsyncThunk<
  null,
  PostRequest<UserData, CommonOthers>
>(
  "np-resource/createUser",
  async ({ data, others: { reRender } }, { rejectWithValue }) => {
    try {
      await AXIOS.post(BASE_API.createUser, data);

      reRender(true);
      toast("success", "PG create successfully");
      return null;
    } catch (err) {
      const errors = err as AxiosError;
      const error = errors.response?.data as ErrorMessage;
      toast("error", error?.message || "PG create fail");
      return rejectWithValue(error);
    }
  }
);

// get all users
export const getAllUsersAsync = createAsyncThunk<
  GetAllUsersData[],
  Params<GetAllUsersParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-resource/getAllUsers", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getAllUsers, {
      params,
    });

    return data.data?.getData as GetAllUsersData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get all users fail");
    return rejectWithValue(error);
  }
});

// get count all users
export const getCountUsersAsync = createAsyncThunk<
  number,
  Params<GetUsersCountParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-resource/getCountUsers", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getCountUsers, {
      params,
    });
    return data.data?.getData || 0;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get user count data fail");
    return rejectWithValue(error);
  }
});
