import { Avatar, Button, Space, Timeline } from "antd";
import { onMessage } from "firebase/messaging";
import moment from "moment";
import { ReactNode, useCallback, useEffect, useState } from "react";
import CheckIcon from "../../../assets/icons/CheckIcon";
import NetworkIcon from "../../../assets/icons/NetworkIcon";
import SuccessTimelineIcon from "../../../assets/icons/SuccessTimelineIcon";
import { generateToken, messaging } from "../../../helpers/firebaseConfig";
import { networkLogViewAsync } from "../../../store/features/NP/network/networkAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CustomModalProps } from "../../../types";
import { GetAllTowerData } from "../../../types/redux";
import { capitalize, firstChar } from "../../../utils/string";
import {
  calculateMinutes,
  formatDate,
  formatTime,
  minutesToHours,
} from "../../../utils/time";
import CustomModal from "../../common/CustomModal";
import Loader from "../../common/Loader";
import LocatorListItem from "../../ui/LocatorListItem";
import DistanceCalculator from "./DistanceCalculator";
import ViewImages from "./ViewImages";

type Props = {
  locatorTitle: string;
  locatorChildren?: ReactNode;
  data?: any;
} & CustomModalProps;

export default function ViewLog({
  locatorTitle,
  locatorChildren,
  handleClose,
  data,
  ...rest
}: Props) {
  const dispatch = useAppDispatch();
  const ticketData = data as GetAllTowerData;
  const [openImg, setOpenImg] = useState(false);
  const { viewLoading, logNetworks } = useAppSelector((state) => state.network);

  const time = {
    start: ticketData?.towerTicketLogData?.pgStart?.time,
    end: ticketData?.towerTicketLogData?.pgOff?.time,
  };

  // console.log(openImg);

  const timelineData = [
    {
      id: 1,
      title: "Accept task request",
      date: logNetworks?.createdAt ? formatDate(logNetworks?.createdAt) : "",
      time: logNetworks?.createdAt
        ? formatTime(logNetworks?.createdAt, "h:mm A")
        : "",
      show: logNetworks?.tower ? true : false,
    },
    {
      id: 2,
      title: "Going to tower location",
      date: logNetworks?.startJourney?.date
        ? formatDate(logNetworks?.startJourney?.date)
        : "",
      time: logNetworks?.startJourney?.time || "",
      show:
        logNetworks?.tower && logNetworks?.startJourney?.date ? true : false,
    },
    {
      id: 3,
      title: "Reached to location",
      date: logNetworks?.reachedLocation?.date
        ? formatDate(logNetworks?.reachedLocation?.date)
        : "",
      time: logNetworks?.reachedLocation?.time || "",
      show:
        logNetworks?.tower &&
        logNetworks?.startJourney?.date &&
        logNetworks?.reachedLocation?.date
          ? true
          : false,
    },
    {
      id: 4,
      title: (
        <div className="flex items-center gap-2">
          <span>Verify Service Product</span>
          {logNetworks?.existImages?.length ||
          logNetworks?.entryImages?.length ? (
            <div
              className={`status border-none ${
                true
                  ? "status-info cursor-pointer"
                  : "status-info !text-gray-400"
              }`}
              onClick={() => setOpenImg(true)}
            >
              View
            </div>
          ) : null}
        </div>
      ),
      pgCheck: logNetworks?.checkList?.map((el) => el?.name),
      show:
        logNetworks?.tower &&
        logNetworks?.startJourney?.date &&
        logNetworks?.reachedLocation?.date &&
        logNetworks?.entryImages?.length
          ? true
          : false,
    },
    {
      id: 5,
      title: "PG Start",
      time: logNetworks?.pgStart?.time || "",
      show:
        logNetworks?.tower &&
        logNetworks?.startJourney?.date &&
        logNetworks?.reachedLocation?.date &&
        logNetworks?.entryImages?.length &&
        logNetworks?.pgStart?.time
          ? true
          : false,
    },
    {
      id: 6,
      title: "PG Off",
      time: logNetworks?.pgOff?.time || "",
      show:
        logNetworks?.tower &&
        logNetworks?.startJourney?.date &&
        logNetworks?.reachedLocation?.date &&
        logNetworks?.entryImages?.length &&
        logNetworks?.pgStart?.time &&
        logNetworks?.pgOff?.time
          ? true
          : false,
    },
    {
      id: 5,
      title: "Access granted",
      date: logNetworks?.existPremises?.date
        ? formatDate(logNetworks?.existPremises?.date)
        : "",
      time: logNetworks?.existPremises?.time || "",
      show:
        logNetworks?.tower &&
        logNetworks?.startJourney?.date &&
        logNetworks?.reachedLocation?.date &&
        logNetworks?.entryImages?.length &&
        logNetworks?.pgStart?.time &&
        logNetworks?.pgOff?.time &&
        logNetworks?.existPremises?.date
          ? true
          : false,
    },
  ];

  const networkData = useCallback(() => {
    dispatch(
      networkLogViewAsync({
        params: { id: ticketData?.towerTicketLogData?._id as string },
      })
    );
  }, [dispatch, ticketData?.towerTicketLogData?._id]);

  useEffect(() => {
    networkData();
  }, [networkData]);

  useEffect(() => {
    generateToken();

    onMessage(messaging, (payload) => {
      const notification = payload?.notification;

      if (notification?.title === "map") {
        networkData();
      }
    });
  }, [networkData]);

  const currentLocation = {
    lat: logNetworks?.pgLat,
    lon: logNetworks?.pgLon,
  };
  const destination = {
    lat: logNetworks?.latitude,
    lon: logNetworks?.longitude,
  };

  const showMap =
    logNetworks?.pgLat &&
    logNetworks?.pgLon &&
    logNetworks?.longitude &&
    logNetworks?.latitude
      ? true
      : false;

  return (
    <CustomModal
      width={showMap ? 1100 : 850}
      handleClose={handleClose}
      {...rest}
    >
      {openImg === true ? (
        <ViewImages
          open={openImg}
          title="Verify Service Product"
          data={{
            entryImages: logNetworks?.entryImages,
            existImages: logNetworks?.existImages,
          }}
          handleClose={() => setOpenImg(false)}
        />
      ) : null}
      <div
        className={`grid gap-2.5 ${
          showMap ? "md:grid-cols-3" : "md:grid-cols-2"
        }`}
      >
        <div className="p-10 bg-gray-50 rounded-bl-2xl">
          <div className="flex justify-center items-center">
            <span
              className={`inline-flex justify-center items-center w-[54.56px] h-[54.56px] text-white rounded-full text-lg ${
                timelineData?.[timelineData?.length - 1]?.show
                  ? "bg-primary-np"
                  : "bg-status-waiting-btn"
              }`}
            >
              <NetworkIcon width="28" height="28" />
            </span>
          </div>

          <h3 className="text-center text-header text-lg font-medium py-3">
            {`${capitalize(ticketData?.towerName)} - ${ticketData?.regionName}`}
          </h3>

          <ul className="rounded border border-zinc-200 border-solid bg-white">
            {locatorChildren || (
              <>
                <LocatorListItem title="Site Code:">
                  {ticketData?.siteCode}
                </LocatorListItem>
                {/* <LocatorListItem title="Structure Type:"></LocatorListItem> */}
                <LocatorListItem title="Location:">
                  {capitalize(ticketData?.towerName)}
                </LocatorListItem>
                <LocatorListItem title="Region:">
                  {ticketData?.regionName}
                </LocatorListItem>
                <LocatorListItem title="Area:">
                  {ticketData?.areaName}
                </LocatorListItem>
                <LocatorListItem title="Territory:">
                  {ticketData?.teritoryName}
                </LocatorListItem>
                <LocatorListItem title="Inactive Time:">
                  {logNetworks?.pgAlert?.startTime?.status === "start" &&
                  logNetworks?.pgAlert?.startTime?.time
                    ? moment(
                        logNetworks?.pgAlert?.startTime?.time,
                        "HH:mm:ss"
                      ).format("hh:mm:ss A")
                    : "N/A"}
                  <br />
                  {logNetworks?.pgAlert?.stopTime?.status === "stop" &&
                  logNetworks?.pgAlert?.stopTime?.time
                    ? moment(
                        logNetworks?.pgAlert?.stopTime?.time,
                        "HH:mm:ss A"
                      ).format("hh:mm:ss A")
                    : "N/A"}
                </LocatorListItem>
                {/* <LocatorListItem title="Electricity Status:">
                  {"N/A"}
                  <br />
                  {"N/A"}
                </LocatorListItem> */}
              </>
            )}
          </ul>
        </div>

        <div
          className={`px-10 mt-10 pb-6 rounded-br-2xl ${
            showMap ? "grid md:grid-cols-2 gap-2.5 md:col-span-2" : ""
          }`}
        >
          <div>
            <Space
              className="table_column_title"
              size={0}
              direction="horizontal"
            >
              <Avatar size={45}>
                {firstChar(ticketData?.PgData?.firstName)}
              </Avatar>
              {/* <Avatar src={IMG} size={45}></Avatar> */}
              <div className="ml-[8px]">
                <h5>{`${ticketData?.PgData?.firstName} ${ticketData?.PgData?.lastName}`}</h5>
                <p className="mb-0.5">Email: {ticketData?.PgData?.email}</p>
                <p>Mobile: {ticketData?.PgData?.phoneNumber}</p>
              </div>
            </Space>

            <div className="border-b-[1.5px] border-solid border-t-0 border-x-0 border-others-gray pt-3 mb-5" />

            <Timeline
              className="px-1"
              items={timelineData?.map((el) => ({
                dot: (
                  <SuccessTimelineIcon
                    color={el?.show ? "#33AB29" : "#AFBACA"}
                  />
                ),
                children: (
                  <>
                    <h5 className="text-base font-medium text-body">
                      {el?.title}
                    </h5>
                    {el?.show ? (
                      <>
                        {el?.date || el?.time ? (
                          <div className="text-accent text-xs mt-1.5 leading-tight tracking-tight">
                            {el?.date ? `${el?.date} - ` : ""}
                            {el?.time}
                          </div>
                        ) : null}
                        {el?.pgCheck?.length ? (
                          <div className="mt-2 flex items-center gap-3 flex-wrap">
                            {el?.pgCheck?.map((item, idx) => (
                              <span
                                className="text-accent flex items-center gap-1.5"
                                key={idx}
                              >
                                <CheckIcon />
                                <span className="text-xs font-normal leading-tight tracking-tight mt-0.5">
                                  {item}
                                </span>
                              </span>
                            ))}
                          </div>
                        ) : null}
                      </>
                    ) : null}
                  </>
                ),
                className: el?.show ? "timeline_active" : "timeline_inactive",
              }))}
            />
          </div>

          {showMap ? (
            <div>
              {viewLoading ? (
                <Loader />
              ) : currentLocation.lat &&
                currentLocation.lon &&
                destination.lat &&
                destination.lon ? (
                <DistanceCalculator
                  currentLocation={currentLocation}
                  destination={destination}
                />
              ) : null}
            </div>
          ) : null}

          <div>
            <div className="px-3.5 py-2.5 mb-3 bg-status-info-bg rounded-xl">
              <div className="flex justify-between items-center gap-2 mb-1">
                <div className="text-status-danger-text text-base font-semibold font-['Inter'] leading-normal">
                  Total Duration:
                </div>
                <div className="text-others-info text-base font-medium font-['Inter'] leading-normal">
                  {time?.start && time?.end
                    ? minutesToHours(calculateMinutes(time?.start, time?.end))
                    : `00:00`}{" "}
                  minutes
                </div>
              </div>
              <div className="flex justify-between items-center gap-2">
                <div className="text-status-danger-text text-base font-semibold font-['Inter'] leading-normal">
                  Payable Bill:
                </div>
                <div className="text-others-info text-base font-medium font-['Inter'] leading-normal">
                  {time?.start && time?.end
                    ? calculateMinutes(time?.start, time?.end) *
                      (ticketData?.towerTicketLastData?.perMinuteCharge || 0)
                    : 0}{" "}
                  BDT
                </div>
              </div>
            </div>

            <div className="gray_btn" onClick={handleClose}>
              <Button block size="large">
                Back
              </Button>
            </div>
          </div>
        </div>
      </div>
    </CustomModal>
  );
}
