import { Select, Space } from "antd";
import SAPaymentTable from "../../../components/SA/Payment/SAPaymentTable";
import Subheader from "../../../components/ui/Subheader";
import { selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";

export default function SAPayment() {
  const handleSiteLocator = (value: string) => {
    console.log(`selected ${value}`);
  };

  return (
    <AdminLayout title="Payment Summary" headerTitle="Payment">
      <section className="shadow-sm mt-7">
        <Subheader title="Payment Summary">
          <Space size="large">
            <Select
              // showSearch
              placeholder="Today"
              // optionFilterProp="children"
              onChange={handleSiteLocator}
              filterOption={selectSearchOption}
              defaultValue="Today"
              className="min-w-[8rem]"
              options={[
                {
                  value: "Today",
                  label: "Today",
                },
                {
                  value: "Tomorrow",
                  label: "Tomorrow",
                },
                {
                  value: "Yesterday",
                  label: "Yesterday",
                },
              ]}
            />
          </Space>
        </Subheader>

        <SAPaymentTable />
      </section>
    </AdminLayout>
  );
}
