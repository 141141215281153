export default function ReachedLocation() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="6" fill="#F2FFF9" />
      <path
        d="M16.25 32.75H29.75M26 20.75C26 22.4069 24.6569 23.75 23 23.75C21.3431 23.75 20 22.4069 20 20.75C20 19.0931 21.3431 17.75 23 17.75C24.6569 17.75 26 19.0931 26 20.75ZM30.5 20.75C30.5 27.5 23 32.75 23 32.75C23 32.75 15.5 27.5 15.5 20.75C15.5 18.7609 16.2902 16.8532 17.6967 15.4467C19.1032 14.0402 21.0109 13.25 23 13.25C24.9891 13.25 26.8968 14.0402 28.3033 15.4467C29.7098 16.8532 30.5 18.7609 30.5 20.75Z"
        stroke="#11A75C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
