export default function PGRunning() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="6" fill="#F2F5FF" />
      <path
        d="M20 33.4993L21.5 25.9993L15.5 23.7493L26 12.4993L24.5 19.9993L30.5 22.2493L20 33.4993Z"
        stroke="#4A72FF"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
