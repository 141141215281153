import img from "../assets/images/placeholder-image.png";
import { stringToArray } from "../utils/array";

export const selectSearchOption = (
  input: string,
  option?: { label: string; value: string }
) => (option?.label ?? "").toLowerCase().includes(input.toLowerCase());

export const addKeyInArray = (data: any) => {
  return data?.map((el: object & { _id?: string }, idx: number) => ({
    ...el,
    key: el?._id || `${idx}`,
  }));
};

export const ArrayOption = (data: any[], label: string, value: string) => {
  return data.map((el) => ({
    label: el[label],
    value: el[value],
  }));
};

// placeholder images
export const IMG = img;

// return path
export const pathLink = (path: string, end: number, start: number = 0) => {
  const pathStr = stringToArray(path, "/")?.slice(start, end);
  return pathStr?.join("/");
};
