import { Form, FormProps, Input, Select } from "antd";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import { ArrayOption, selectSearchOption } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  createTerritoryAsync,
  getAllAreaAsync,
  getAllRegionAsync,
  getSingleTerritoryAsync,
  updateTerritoryAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { TerritoryData } from "../../../types/redux";

const navLink = "/sa/geo-info/territory";

export default function ManageTerritory() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectRegion, setSelectRegion] = useState("");
  const [selectArea, setSelectArea] = useState("");
  const {
    postLoading,
    regions,
    areas,
    loading,
    updateLoading,
    singleTerritory,
  } = useAppSelector((state) => state.npGeo);

  const onFinish: FormProps<TerritoryData>["onFinish"] = (values) => {
    if (id) {
      dispatch(
        updateTerritoryAsync({
          data: values,
          others: {
            reRender,
            id,
          },
        })
      );
    } else {
      dispatch(
        createTerritoryAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    dispatch(getAllRegionAsync({}));
    dispatch(
      getAllAreaAsync({
        params: {
          region: selectRegion,
        },
      })
    );
  }, [dispatch, selectRegion]);

  useEffect(() => {
    if (id && singleTerritory && updateLoading === false) {
      setSelectRegion(singleTerritory?.region?._id);
      setSelectArea(singleTerritory?.area?._id);

      form.setFieldsValue({
        region: singleTerritory?.region?._id,
        area: singleTerritory?.area?._id,
        teritoryName: singleTerritory?.teritoryName,
      });
    }
  }, [form, id, singleTerritory, updateLoading]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleTerritoryAsync({ params: { id } }));
    }
  }, [dispatch, id]);

  return (
    <AdminLayout
      title={id ? "Update territory" : "Add new territory"}
      headerTitle="Geo Information"
    >
      <CustomForm<TerritoryData>
        onFinish={onFinish}
        title={id ? "Update territory" : "Add new territory"}
        formTitle={id ? "Update Territory" : "Add Territory"}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="region"
          label="Select Region"
          rules={[
            {
              required: true,
              message: "Please select region",
            },
          ]}
        >
          <Select
            placeholder="Select region"
            size="large"
            allowClear
            showSearch
            loading={loading}
            filterOption={selectSearchOption}
            options={ArrayOption(regions, "regionName", "_id")}
            onChange={(val) => {
              setSelectRegion(val);
              setSelectArea("");
              form.setFieldValue("area", undefined);
              form.setFieldValue("teritoryName", undefined);
            }}
            disabled={id ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="area"
          label="Select Area"
          rules={[
            {
              required: true,
              message: "Please select area",
            },
          ]}
        >
          <Select
            placeholder="Select area"
            size="large"
            allowClear
            showSearch
            loading={loading}
            filterOption={selectSearchOption}
            options={ArrayOption(areas, "areaName", "_id")}
            onChange={(val) => {
              setSelectArea(val);
              form.setFieldValue("teritoryName", undefined);
            }}
            disabled={!selectRegion || id ? true : false}
          />
        </Form.Item>
        <Form.Item
          name="teritoryName"
          label="Territory Name"
          rules={[
            {
              required: true,
              message: "Please enter territory name",
            },
          ]}
        >
          <Input
            size="large"
            disabled={!selectArea}
            placeholder="Enter area name"
          />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
