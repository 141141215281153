export default function RefreshIcon() {
  return (
    <svg
      width="14"
      height="12"
      viewBox="0 0 16 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.6134 8.98872H1.2384M1.2384 8.98872V12.3637M1.2384 8.98872L3.62492 11.3752C4.19948 11.9498 4.88159 12.4056 5.63229 12.7165C6.38299 13.0275 7.18759 13.1875 8.00015 13.1875C8.8127 13.1875 9.6173 13.0275 10.368 12.7165C11.1187 12.4056 11.8008 11.9498 12.3754 11.3752M11.3869 5.01128H14.7619M14.7619 5.01128V1.63628M14.7619 5.01128L12.3754 2.62478C11.8008 2.05021 11.1187 1.59445 10.368 1.2835C9.6173 0.972545 8.8127 0.8125 8.00015 0.8125C7.18759 0.8125 6.38299 0.972545 5.63229 1.2835C4.88159 1.59445 4.19948 2.05021 3.62492 2.62478"
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
