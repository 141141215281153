import { InfoCircleOutlined, UserOutlined } from "@ant-design/icons";
import {
  Checkbox,
  CheckboxProps,
  Form,
  FormProps,
  Input,
  Select,
  Tooltip,
} from "antd";
import CustomForm from "../../../components/common/CustomForm";
import AdminLayout from "../../../layouts/AdminLayout";

type FieldType = {
  name: string;
};

export default function SANetworkDetails() {
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
  };

  const onChange: CheckboxProps["onChange"] = (e) => {
    console.log(`checked = ${e.target.checked}`);
  };

  return (
    <AdminLayout title="Add New Tower" headerTitle="Networks">
      <CustomForm<FieldType>
        onFinish={onFinish}
        title="Add New Tower"
        formTitle="Add New Tower information"
        backLink="/sa/networks"
      >
        <Form.Item
          name="tower_name"
          label="Tower Name"
          rules={[
            {
              required: true,
              message: "Please enter tower name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter tower name" />
        </Form.Item>

        <Form.Item
          name="contact_number"
          label="Land Proprietor Contact no"
          rules={[
            {
              required: true,
              message: "Please enter mobile no",
            },
          ]}
        >
          <Input size="large" placeholder="Mobile No:" />
        </Form.Item>

        <Form.Item label="Region">
          <Select>
            <Select.Option value="demo">Dhaka</Select.Option>
            <Select.Option value="demo">Rajshahi</Select.Option>
            <Select.Option value="demo">Chittagong</Select.Option>
            <Select.Option value="demo">Borisal</Select.Option>
            <Select.Option value="demo">Khulna</Select.Option>
            <Select.Option value="demo">Pabna</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Area">
          <Select>
            <Select.Option value="demo">South</Select.Option>
            <Select.Option value="demo">North</Select.Option>
            <Select.Option value="demo">Mymenshingh</Select.Option>
            <Select.Option value="demo">Dhaka</Select.Option>
            <Select.Option value="demo">Chattogram</Select.Option>
            <Select.Option value="demo">Munshiganj</Select.Option>
            <Select.Option value="demo">Sylhet</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="Territory">
          <Select>
            <Select.Option value="demo">Rupganj</Select.Option>
            <Select.Option value="demo">Mugda</Select.Option>
            <Select.Option value="demo">Rohitpur</Select.Option>
            <Select.Option value="demo">Nobabgonj</Select.Option>
            <Select.Option value="demo">Sirazdi lhan bazar</Select.Option>
            <Select.Option value="demo">Khilgoan</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item label="GEO">
          <Select>
            <Select.Option value="demo">Demo 1</Select.Option>
            <Select.Option value="demo">Demo 2</Select.Option>
            <Select.Option value="demo">Demo 3</Select.Option>
            <Select.Option value="demo">Demo 4</Select.Option>
            <Select.Option value="demo">Demo 5</Select.Option>
          </Select>
        </Form.Item>

        <Form.Item
          name="latitude"
          label="Latitude"
          rules={[
            {
              required: true,
              message: "Please enter latitude no",
            },
          ]}
        >
          <Input size="large" placeholder="23.7985053" />
        </Form.Item>

        <Form.Item
          name="longitude"
          label="Longitude"
          rules={[
            {
              required: true,
              message: "Please enter longitude no",
            },
          ]}
        >
          <Input size="large" placeholder="90.3842538" />
        </Form.Item>

        <Form.Item
          name="radius"
          label="Radius"
          rules={[
            {
              required: true,
              message: "Please enter radius no",
            },
          ]}
        >
          <Input size="large" placeholder="5" />
        </Form.Item>

        <Input
          name="radius"
          size="large"
          placeholder="234GRD87X"
          prefix={<UserOutlined style={{ color: "rgba(0,0,0,.25)" }} />}
          suffix={
            <Tooltip title="Extra information">
              <InfoCircleOutlined style={{ color: "rgba(0,0,0,.45)" }} />
            </Tooltip>
          }
        />

        {/* checkbox */}
        <br />
        <br />
        <p className="text-primary-np text[18px]">PG check Permissions:</p>
        <br />
        <div className="grid grid-cols-2 gap-x-2">
          <Checkbox onChange={onChange}>Left Right photos</Checkbox>
          <Checkbox onChange={onChange}>Front Back photos</Checkbox>
          <Checkbox onChange={onChange}>Circuit Breaker photos</Checkbox>
          <Checkbox onChange={onChange}>3 modules</Checkbox>
          <Checkbox onChange={onChange}>Site is up photos</Checkbox>
          <Checkbox onChange={onChange}>Battery</Checkbox>
          <Checkbox onChange={onChange}>IVS check</Checkbox>
          <Checkbox onChange={onChange}>Fan check</Checkbox>
          <Checkbox onChange={onChange}>Take circuit board photos</Checkbox>
        </div>
        <br />
        <br />
      </CustomForm>
    </AdminLayout>
  );
}
