import { Button, Checkbox, Form, Input } from "antd";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import EmailIcon from "../../assets/icons/EmailIcon";
import KeyIcon from "../../assets/icons/KeyIcon";
import useRememberMe from "../../hooks/useRememberMe";
import LoginLayout from "../../layouts/LoginLayout";
import { loginUserAsync } from "../../store/features/auth/loginAPI";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { LoginData } from "../../types/redux";

type InitialLoginValue = {
  email: string;
  password: string;
  remember: boolean;
};

const initialValues = {
  email: "",
  password: "",
  remember: false,
};

const Login: React.FC = () => {
  const [form] = Form.useForm();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { handleRemember, remember, saveRemember, values } =
    useRememberMe<InitialLoginValue>("SA", initialValues);
  const { loading: loginLoading, notificationToken } = useAppSelector(
    (state) => state.login
  );

  useEffect(() => {
    if (values) {
      form.setFieldsValue({
        email: values?.email,
        password: values?.password,
      });
    }
  }, [form, values]);

  const onFinish = (values: LoginData) => {
    saveRemember({
      email: values?.email,
      password: values?.password,
      remember,
    });

    dispatch(
      loginUserAsync({
        data: {
          ...values,
          isPG: false,
          mobileToken: notificationToken,
          loginRole: "SA",
        },
        others: {
          reRender,
        },
      })
    );
  };

  function reRender(val: boolean) {
    if (val) navigate("/sa/dashboard");
  }

  return (
    <LoginLayout bgColor="bg-primary-sa">
      <Form
        name="form_item_path"
        className="custom-form login-form"
        layout="vertical"
        onFinish={onFinish}
        requiredMark="optional"
        size="large"
        form={form}
      >
        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please enter your email!",
            },
          ]}
        >
          <Input prefix={<EmailIcon />} placeholder="example@email.com" />
        </Form.Item>
        <Form.Item
          name="password"
          label="Password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input.Password prefix={<KeyIcon />} placeholder="Password" />
        </Form.Item>
        <Form.Item className="mt-5">
          <Button
            type="primary"
            htmlType="submit"
            size="large"
            loading={loginLoading}
            block
          >
            {loginLoading ? "Loading..." : "Log in"}
          </Button>
        </Form.Item>

        <div className="flex justify-between items-center">
          <Checkbox
            className="select-none font-medium"
            checked={remember}
            onChange={handleRemember}
          >
            Remember me
          </Checkbox>

          <Link
            to=""
            className="ml-auto hover:underline text-body hover:text-body/80"
          >
            Forgot password?
          </Link>
        </div>
      </Form>
      {/* <p className="text-center font-medium mt-8">
            Don't have an account? 
            <Link
              className="text-primary-np no-underline hover:underline"
              to="sign-up"
            >
              Sign Up
            </Link>
          </p> */}
    </LoginLayout>
  );
};

export default Login;
