import { Form, FormProps, Input } from "antd";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomForm from "../../../components/common/CustomForm";
import { pathLink } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  createRegionAsync,
  getSingleRegionAsync,
  updateRegionAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { RegionData } from "../../../types/redux";

export default function ManageRegion() {
  const { id } = useParams();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { postLoading, updateLoading, singleRegion } = useAppSelector(
    (state) => state.npGeo
  );
  const location = useLocation();
  const path = pathLink(location.pathname, 1);
  const navLink = `/${path}/geo-info/region`;

  const onFinish: FormProps<RegionData>["onFinish"] = (values) => {
    if (id) {
      dispatch(
        updateRegionAsync({
          data: values,
          others: {
            reRender,
            id,
          },
        })
      );
    } else {
      dispatch(
        createRegionAsync({
          data: values,
          others: {
            reRender,
          },
        })
      );
    }
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  useEffect(() => {
    if (id && singleRegion && updateLoading === false) {
      form.setFieldsValue({
        regionName: singleRegion.regionName,
      });
    }
  }, [form, id, singleRegion, updateLoading]);

  useEffect(() => {
    if (id) {
      dispatch(getSingleRegionAsync({ params: { id } }));
    }
  }, [dispatch, id]);

  return (
    <AdminLayout
      title={id ? "Update region" : "Add new region"}
      headerTitle="Geo Informations"
    >
      <CustomForm<RegionData>
        onFinish={onFinish}
        title={id ? "Update region" : "Add new region"}
        formTitle={id ? "Update Region" : "Add Region"}
        backLink={navLink}
        disabled={postLoading || updateLoading}
        isUpdate={id ? true : false}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="regionName"
          label="Region Name"
          rules={[
            {
              required: true,
              message: "Please enter region name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter region name" />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
