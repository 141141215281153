export default function JourneyStarted() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="6" fill="#FFF7E6" />
      <path
        d="M26 17.75L32 16.25V29.75L26 31.25M26 17.75L20 14.75M26 17.75V31.25M26 31.25L20 28.25M20 28.25V14.75M20 28.25L14 29.75V16.25L20 14.75"
        stroke="#FFC700"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
