import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import { NetworkTableColumn } from "../../../types";
import CustomTable from "../../common/CustomTable";

export default function SATaskTable() {
  const { limit, page, handlePagination } = useTable();
  console.log(limit);

  const columns: ColumnsType<NetworkTableColumn> = [
    {
      title: "Tracking No",
      render: (val) => (
        <Space className="table_column_title" size={0} direction="vertical">
          <h5>{val?.name}</h5>
          <p>Ref: {val?.ref}</p>
        </Space>
      ),
    },
    {
      title: "Status",
      align: "center",
      render: (val: NetworkTableColumn) => (
        <span
          className={`status ${
            val?.status === "accepted"
              ? "status-done"
              : val?.status === "paused"
              ? "status-danger"
              : val?.status === "ongoing"
              ? "status-done"
              : val?.status === "inactive"
              ? "status-danger"
              : null
          }`}
        >
          {val?.status}
        </span>
      ),
    },

    {
      title: "Task Start",
      dataIndex: "task_status",
      align: "center",
    },
    {
      title: "Task End",
      dataIndex: "task_end",
      align: "center",
    },
    {
      title: "Total Duration",
      dataIndex: "duration",
      align: "center",
    },

    {
      title: "Action",
      align: "center",
      render: (val: NetworkTableColumn) => (
        <Button type="primary" size="large">
          View
        </Button>
      ),
    },
  ];

  return (
    <CustomTable<NetworkTableColumn>
      loading={false}
      total={20}
      page={page}
      handlePagination={handlePagination}
      columns={columns}
      dataList={addKeyInArray(data)}
    />
  );
}

const data = [
  {
    _id: "6",
    name: "Harbaid",
    ref: "e.coBD001846GP",
    status: "ongoing",
    task_status: "02:40 PM",
    task_end: "03:20 PM",
    duration: "02:10:34 min",
  },
  {
    _id: "5",
    name: "Pubail_02_sylhet",
    ref: "e.coBD001846GP",
    status: "ongoing",
    task_status: "02:40 PM",
    task_end: "03:20 PM",
    duration: "02:10:34 min",
  },
  {
    _id: "4",
    name: "Harbaid",
    ref: "e.coBD001846GP",
    status: "paused",
    task_status: "02:40 PM",
    task_end: "03:20 PM",
    duration: "02:10:34 min",
  },
  {
    _id: "3",
    name: "Pubail_02_sylhet",
    ref: "e.coBD001846GP",
    status: "accepted",
    task_status: "02:40 PM",
    task_end: "03:20 PM",
    duration: "02:10:34 min",
  },
  {
    _id: "2",
    name: "Harbaid",
    ref: "e.coBD001846GP",
    status: "inactive",
    task_status: "02:40 PM",
    task_end: "03:20 PM",
    duration: "02:10:34 min",
  },
  {
    _id: "22",
    name: "Pubail_02_sylhet",
    ref: "e.coBD001846GP",
    status: "accepted",
    task_status: "02:40 PM",
    task_end: "03:20 PM",
    duration: "02:10:34 min",
  },
  {
    _id: "23",
    name: "Harbaid",
    ref: "e.coBD001846GP",
    status: "ongoing",
    task_status: "02:40 PM",
    task_end: "03:20 PM",
    duration: "02:10:34 min",
  },
];
