import { Pagination, PaginationProps, Table } from "antd";
import { AnyObject } from "antd/es/_util/type";
import { ColumnsType } from "antd/es/table";
import NextIcon from "../../assets/icons/NextIcon";
import PrevIcon from "../../assets/icons/PrevIcon";

interface Props<DataListType> {
  loading: boolean;
  total: number;
  columns: ColumnsType<DataListType>;
  dataList: DataListType[];
  page: number;
  limit?: number;
  isSearch?: boolean;
  isPagination?: boolean;
  bordered?: boolean;
  handlePagination?: (paginationPage: number, paginationLimit: number) => void;
}

const CustomTable = <DataListType extends AnyObject>({
  loading,
  total,
  limit = 10,
  page,
  columns,
  dataList,
  isPagination = true,
  bordered = true,
  handlePagination,
}: Props<DataListType>) => {
  const itemRender: PaginationProps["itemRender"] = (
    _,
    type,
    originalElement
  ) => {
    if (type === "prev") {
      return (
        <span>
          <PrevIcon />
        </span>
      );
    }
    if (type === "next") {
      return (
        <span>
          <NextIcon />
        </span>
      );
    }
    return originalElement;
  };

  return (
    <div className="p-5">
      <Table
        columns={columns}
        dataSource={dataList}
        loading={loading}
        pagination={false}
        className="custom_edotco_table color_table custom-sidebar"
        bordered={bordered}
      />

      {isPagination && dataList?.length ? (
        <div className="flex justify-center mt-6 relative custom_pagination">
          <Pagination
            onChange={handlePagination}
            current={page}
            showSizeChanger={false}
            total={total}
            defaultPageSize={limit}
            itemRender={itemRender}
          />
        </div>
      ) : null}
    </div>
  );
};

export default CustomTable;
