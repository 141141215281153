import { Button } from "antd";
import Dragger from "antd/es/upload/Dragger";
import moment from "moment";
import { useEffect, useState } from "react";
import * as XLSX from "xlsx";
import AXIOS from "../../../helpers/api";
import { BASE_API } from "../../../helpers/apiUrl";
import { toast } from "../../../helpers/toast";
import useExcelDownload from "../../../hooks/useExcelDownload";
import { uploadTicketExcelAsync } from "../../../store/features/NP/network/networkAPI";
import { getAllUsersAsync } from "../../../store/features/NP/resource/resourceAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetAllTowerData } from "../../../types/redux";
import { stringToArray } from "../../../utils/array";
import { capitalize, sliceString } from "../../../utils/string";
import { formatDate } from "../../../utils/time";
import CustomModal from "../../common/CustomModal";

type Props = {
  open: boolean;
  handleClose: () => void;
  data?: any;
  reRender: (val: boolean) => void;
  uploadedCount: (val: number, total: number) => void;
};

export default function ImportExcel({
  open,
  handleClose,
  data,
  reRender,
  uploadedCount,
}: Props) {
  const dispatch = useAppDispatch();
  const [fileName, setFileName] = useState("No file chosen");
  const [loading, setLoading] = useState(false);
  const [uploadedData, setUploadedData] = useState<any[]>([]);
  const { allUsers, loading: userLoading } = useAppSelector(
    (state) => state.resource
  );
  const { excelLoading, downloadExcel, handleExcelLoading } =
    useExcelDownload("Tower-List");
  const [uploadLoading, setUploadLoading] = useState(false);
  const { postLoading } = useAppSelector((state) => state.network);

  const handleUploadFile = (file: any) => {
    if (
      file?.name?.endsWith(".xls") ||
      file?.name?.endsWith(".xlsx") ||
      file?.type === "application/vnd.ms-excel" ||
      file?.type ===
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    ) {
      setLoading(true);
      const reader = new FileReader();
      reader.readAsBinaryString(file);

      const fileNameArr = stringToArray(file?.name, ".");
      setFileName(
        `${sliceString(file?.name, 30, "...")}.${
          fileNameArr?.[fileNameArr?.length - 1]
        }`
      );

      reader.onload = (e) => {
        const data = e?.target?.result;
        const workBook = XLSX.read(data, {
          type: "binary",
          cellDates: true,
          dateNF: "mm/dd/yyyy",
        });
        const sheetName = workBook.SheetNames[0];
        const sheet = workBook.Sheets[sheetName];
        const parsedData = XLSX.utils.sheet_to_json(sheet, { raw: false });

        const filteredData = parsedData?.filter(
          (el: any) =>
            el?.["ID"] &&
            el?.["Tower Name"] &&
            (el?.["Per Minute Charge"] || el?.["Per Minute Charge"] === 0) &&
            el?.["Date"] &&
            el?.["Time"]
        );

        setUploadedData(filteredData);
        setLoading(false);
      };
    } else {
      setLoading(false);
      toast("error", "Please upload only excel file");
    }
  };

  const handleDownloadExcel = async () => {
    handleExcelLoading(true);
    let excelData = [] as any[];

    await AXIOS.post(BASE_API.getAllTower, null, {
      params: { limit: 1000, skip: 0 },
    })
      .then((res) => {
        const responseData = res?.data?.getData as GetAllTowerData[];

        responseData?.forEach((el, idx) => {
          let data = {} as any;

          data["ID"] = el?._id;
          data["Tower Name"] = capitalize(el?.towerName);
          // data["Per Minute Charge"] = idx === 0 ? 10 : "";
          // data["Date"] = idx === 0 ? formatDate(new Date(), "DD/MM/YYYY") : "";
          // data["Time"] = idx === 0 ? formatTime(new Date(), "hh:mm A") : "";
          data["Per Minute Charge"] = "";
          data["Date"] = formatDate(new Date(), "MM/DD/YYYY");
          data["Time"] = "";

          excelData.push(data);
        });
      })
      .catch(() => {
        handleExcelLoading(false);
        toast("error", "Download fail! Please try again");
      });

    downloadExcel(excelData);
  };

  const handleUpload = async () => {
    setUploadLoading(true);
    let uploadData = [] as any[];

    try {
      const promises = uploadedData?.map((el: any) =>
        AXIOS.get(`${BASE_API.getSingleTicket}/${el["ID"]}`)
          .then((data) => {
            const ticketData = data?.data?.getData;

            const {
              checkList,
              longitude,
              latitude,
              contactNo,
              towerName,
              _id,
              siteCode,
              region,
              regionName,
              area,
              areaName,
              teritory,
              teritoryName,
              optionalContactNo,
              address,
              radius,
              kpi,
              contactPersonName,
            } = ticketData;

            // console.log(
            //   el["Date"],
            //   moment(new Date(el["Date"])).format("DD/MM/YYYY")
            // );

            const newData = {
              region: region?._id,
              area: area?._id,
              teritory: teritory?._id,
              regionName,
              areaName,
              teritoryName,
              checkList,
              longitude,
              latitude,
              contactNo,
              towerName,
              siteCode,
              tower: _id,
              offTime: formatTicketTime(el["Time"]),
              date: moment(new Date(el["Date"])).format("DD/MM/YYYY"),
              perMinuteCharge: el["Per Minute Charge"],
              requestList: allUsers
                ?.filter((item) => item?.teritory?._id === teritory?._id)
                ?.map((el) => ({
                  user: el?._id,
                })),
              optionalContactNo,
              address,
              radius,
              kpi,
              contactPersonName,
            };

            uploadData.push(newData);
          })
          .catch((error) => {
            console.error(`Error fetching data for ID: ${el["ID"]}`, error);
            toast("error", "Fail to upload data");
          })
      );

      // Wait for all the promises to resolve
      await Promise.all(promises);

      if (uploadData.length > 0) {
        dispatch(
          uploadTicketExcelAsync({
            data: uploadData,
            others: {
              reRender,
              uploadedCount,
            },
          })
        );
      }
    } catch (error) {
      console.error("Error during the upload process", error);
      toast("error", "Error during the upload process");
    } finally {
      setUploadLoading(false);
    }
  };

  // function successDone(id: string) {
  //   setCountUpload((prev) => prev + 1);
  //   dispatch(
  //     towerTicketNotificationAsync({
  //       data: { towerTicketId: id },
  //       others: null,
  //     })
  //   );
  // }

  useEffect(() => {
    dispatch(getAllUsersAsync({}));
  }, [dispatch]);

  return (
    <CustomModal
      width={600}
      title="Excel Sheet"
      open={open}
      handleClose={!uploadLoading && !postLoading ? handleClose : () => {}}
    >
      <div className="p-6">
        <label className="text-[#3d4a5c] text-sm font-medium leading-normal mb-1.5 inline-block">
          Import Excel File
        </label>
        <Dragger
          name="file"
          action=""
          accept=".xlsx, .xls"
          maxCount={1}
          showUploadList={false}
          beforeUpload={() => false}
          onChange={(info) => handleUploadFile(info.file)}
          onDrop={(e) => handleUploadFile(e.dataTransfer.files[0])}
          // excelPostLoading ||
          disabled={loading || uploadLoading || postLoading}
          className="import_excel"
        >
          <Button
            className="flex justify-between items-center"
            size="large"
            disabled={loading || uploadLoading || postLoading}
            style={{ width: "100%" }}
          >
            <div className="flex items-center gap-2 text-sm font-normal font-roboto text-[#8797ae]">
              <span className="text-accent font-medium text-base leading-7 relative">
                Choose File
              </span>
              <span className="border-r border-solid border-l-0 border-y-0 border-others-border h-4" />
              {loading ? "Uploading..." : fileName}
            </div>
          </Button>
        </Dragger>

        <div className="px-4 py-[9px] bg-[#fffbe6] rounded-md border border-[#ffe58f] text-sm text-[#874d00] mt-4">
          <span className="font-medium">Warning! </span>
          <span>
            Upload only xlsx file. For any blank column put N/A, otherwise data
            will be mismatch. Your can see sample file,{" "}
          </span>
          <button
            className="text-[#1890ff] cursor-pointer text-sm font-medium bg-transparent border-none"
            onClick={handleDownloadExcel}
            disabled={excelLoading}
          >
            click here
          </button>
        </div>

        <div className="flex justify-center mt-7">
          <Button
            size="large"
            type="primary"
            className="w-72 !h-12"
            onClick={handleUpload}
            disabled={loading || userLoading || uploadedData?.length === 0}
            loading={uploadLoading || postLoading}
          >
            Upload
          </Button>
        </div>
      </div>
    </CustomModal>
  );
}

function formatTicketTime(input: string): string {
  const timeOnlyPattern = /^\d{1,2}:\d{2}\s?(AM|PM)$/i;

  let date: Date;

  if (timeOnlyPattern.test(input)) {
    const today = new Date();
    const todayDateString = today.toISOString().split("T")[0];
    const dateTimeString = `${todayDateString} ${input}`;
    date = new Date(dateTimeString);
  } else {
    date = new Date(input);
  }

  // Return the time in 12-hour format with AM/PM
  return date.toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });
}
