import { createSlice } from "@reduxjs/toolkit";
import { GetAllUsersData } from "../../../../types/redux";
import {
  GetAllSupplierData,
  NPSupplierInitialStatus,
} from "../../../../types/redux/NP/supplier";
import {
  createSupplierAsync,
  getAllSupplierAsync,
  getCountSupplierAsync,
  getCountUserSupplierAsync,
  getSupplierProfileAsync,
  getSupplierUserAsync,
} from "./supplierAPI";

const initialState: NPSupplierInitialStatus<
  GetAllSupplierData,
  GetAllUsersData
> = {
  loading: false,
  postLoading: false,
  updateLoading: false,
  deleteLoading: false,
  allSupplier: [],
  userSupplier: [],
  supplierProfile: null,
  supplierCount: 0,
  supplierUserCount: 0,
};

const supplierSlice = createSlice({
  name: "np-supplier",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // create user
    builder
      .addCase(createSupplierAsync.pending, (state) => {
        state.postLoading = true;
      })
      .addCase(createSupplierAsync.fulfilled, (state) => {
        state.postLoading = false;
      })
      .addCase(createSupplierAsync.rejected, (state) => {
        state.postLoading = false;
      });
    // get all towers
    builder
      .addCase(getAllSupplierAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getAllSupplierAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.allSupplier = action.payload;
      })
      .addCase(getAllSupplierAsync.rejected, (state) => {
        state.loading = false;
        state.allSupplier = [];
      });
    // get supplier profile
    builder
      .addCase(getSupplierProfileAsync.pending, (state) => {
        state.viewLoading = true;
        state.supplierProfile = null;
      })
      .addCase(getSupplierProfileAsync.fulfilled, (state, action) => {
        state.viewLoading = false;
        state.supplierProfile = action.payload;
      })
      .addCase(getSupplierProfileAsync.rejected, (state) => {
        state.viewLoading = false;
        state.supplierProfile = null;
      });
    // get supplier count
    builder
      .addCase(getCountSupplierAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountSupplierAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.supplierCount = action.payload;
      })
      .addCase(getCountSupplierAsync.rejected, (state) => {
        state.loading = false;
        state.supplierCount = 0;
      });
    // get user suppliers
    builder
      .addCase(getSupplierUserAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getSupplierUserAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.userSupplier = action.payload;
      })
      .addCase(getSupplierUserAsync.rejected, (state) => {
        state.loading = false;
        state.userSupplier = [];
      });
    // get supplier user count
    builder
      .addCase(getCountUserSupplierAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCountUserSupplierAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.supplierUserCount = action.payload;
      })
      .addCase(getCountUserSupplierAsync.rejected, (state) => {
        state.loading = false;
        state.supplierUserCount = 0;
      });
  },
});

export default supplierSlice;
