import { ReactNode, useEffect } from "react";
import { Link } from "react-router-dom";
import EmailUserIcon from "../../../assets/icons/EmailUserIcon";
import PhoneIcon from "../../../assets/icons/PhoneIcon";
import UserIcon from "../../../assets/icons/UserIcon";
import IconButton from "../../../components/common/IconButton";
import Image from "../../../components/common/Image";
import Loader from "../../../components/common/Loader";
import { IMG } from "../../../helpers/siteConfig";
import AdminLayout from "../../../layouts/AdminLayout";
import { getSupplierProfileAsync } from "../../../store/features/NP/supplier/supplierAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";

export default function Profile() {
  const dispatch = useAppDispatch();
  const { supplierProfile, viewLoading } = useAppSelector(
    (state) => state.supplier
  );

  //   console.log(supplierProfile);

  useEffect(() => {
    dispatch(getSupplierProfileAsync());
  }, [dispatch]);

  return (
    <AdminLayout
      title="Profile"
      headerTitle="Profile"
      headerChildren={
        <Link
          to={`/vendor/supplier/${supplierProfile?.suppler?._id}?name=${supplierProfile?.suppler?.supplierName}`}
        >
          <IconButton>User List</IconButton>
        </Link>
      }
    >
      <div className="max-w-[500px] mx-auto mt-10">
        {viewLoading ? (
          <Loader />
        ) : (
          <div className="rounded-md border border-status-info-bg border-solid bg-white">
            <div className="h-16 p-4 bg-status-info-bg rounded-t-md">
              <Image
                width={70}
                height={70}
                rounded="full"
                src={IMG}
                style={{
                  border: "2px solid #fff",
                }}
              />
            </div>
            <div className="p-4 pt-8">
              <h4 className="text-body text-lg font-semibold">
                {supplierProfile?.firstName} {supplierProfile?.lastName}
              </h4>
              <div className="pl-1.5 pr-2 py-1 bg-status-info-bg rounded-md justify-center items-center gap-2.5 inline-flex mt-1">
                <div className="justify-start items-center gap-1.5 flex">
                  {/* <SystemAdminIcon /> */}
                  <div className="text-status-info-text text-sm font-medium">
                    System Vendor
                    {/* {siteRole === "FINANCE" ? login?.role : "System Admin"} */}
                  </div>
                </div>
              </div>
            </div>

            <ul className="list-none -mt-1">
              {/* <UserInfoData icon={<UserIcon />} title="User ID" value="A234567" /> */}
              <UserInfoData
                icon={<PhoneIcon />}
                title="Mobile Number"
                value={supplierProfile?.phoneNumber}
              />
              <UserInfoData
                icon={<EmailUserIcon />}
                title="Email Address"
                value={supplierProfile?.email}
              />
              <UserInfoData
                icon={<UserIcon />}
                title="Supplier Address"
                value={supplierProfile?.suppler?.supplierAddress}
              />
            </ul>
          </div>
        )}
      </div>
    </AdminLayout>
  );
}

type UserInfoProps = {
  icon: ReactNode;
  title: string;
  value: string;
};

const UserInfoData = ({ icon, title, value }: UserInfoProps) => (
  <li className="flex items-center gap-3 border-t border-solid border-b-0 border-x-0 border-status-info-bg py-2.5 px-4">
    <div className="w-12">{icon}</div>
    <div className="flex flex-col justify-between">
      <span className="text-accent !text-sm !font-medium mb-0.5">{title}</span>
      <strong className="text-others-table-header !text-base !font-medium">
        {value}
      </strong>
    </div>
  </li>
);
