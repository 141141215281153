import { HTMLProps, ReactNode } from "react";

type Props = {
  title?: string;
  className?: string;
  children: ReactNode;
} & HTMLProps<HTMLDivElement>;

export default function ServiceHistory({
  title = "Service History",
  className = "",
  children,
  ...rest
}: Props) {
  return (
    <div
      className={`rounded-lg border border-indigo-300 border-solid bg-white px-3.5 py-2.5 mb-5 ${className}`}
      {...rest}
    >
      <h4 className="text-header text-lg font-medium pb-2">{title}</h4>
      <ul>{children}</ul>
    </div>
  );
}

type ItemProps = {
  title: string;
  children: ReactNode;
};

export const HistoryListItem = ({ title, children }: ItemProps) => (
  <li className="py-1 flex justify-between items-center">
    <span className="text-status-info-text text-sm font-medium">{title}</span>
    <span className="text-accent text-sm font-medium text-right">
      {children}
    </span>
  </li>
);
