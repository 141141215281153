export default function UploadIcon() {
  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.1875 8.6875V12.625C13.1875 12.7742 13.1282 12.9173 13.0227 13.0227C12.9173 13.1282 12.7742 13.1875 12.625 13.1875H1.375C1.22582 13.1875 1.08274 13.1282 0.977253 13.0227C0.871763 12.9173 0.8125 12.7742 0.8125 12.625V8.6875M4.04688 3.76485L7 0.8125L9.95312 3.76485M7 8.6875V0.814569"
        stroke="currentColor"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
