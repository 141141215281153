import { Select, Space } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import UploadIcon from "../../../assets/icons/UploadIcon";
import NetworkTable from "../../../components/NP/Network/NetworkTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { selectSearchOption } from "../../../helpers/siteConfig";
import useDebounce from "../../../hooks/useDebounce";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { useAppSelector } from "../../../store/store";
import { AddZero } from "../../../utils/number";

export default function Network() {
  const [searchParams] = useSearchParams();
  const [status, setStatus] = useState<string>(
    (searchParams.get("status") as string)
      ? (searchParams.get("status") as string)
      : (searchParams.get("pg") as string)
      ? `pg-${searchParams.get("pg")}`
      : ""
  );

  const { open, toggle, handleOpen } = useModal();
  const { handleSetFirstPage, ...rest } = useTable();
  const { towersCount, allTowers, loading } = useAppSelector(
    (state) => state.network
  );
  const { keyword, handleInputChange, handleEnterValue } = useDebounce();
  const {
    keyword: siteCode,
    handleInputChange: handleInputChange2,
    handleEnterValue: handleEnterValue2,
  } = useDebounce();

  const handleSiteLocator = (value: string) => {
    setStatus(value);
    handleSetFirstPage(true);
  };

  return (
    <AdminLayout title="Networks" headerTitle="Networks">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Site Locator List"
          subTitle={`Total Network: ${AddZero(
            (keyword || siteCode) && !loading ? allTowers?.length : towersCount
          )}`}
        >
          <Space size="middle" className="flex-wrap">
            <Search
              placeholder="Search by site code"
              onSearch={(val) => {
                handleEnterValue2(val);
                handleSetFirstPage(true);
              }}
              onChange={(val) => {
                handleInputChange2(val);
                handleSetFirstPage(true);
              }}
              className="custom-search-input"
            />

            <Search
              placeholder="Search by tower name"
              onSearch={(val) => {
                handleEnterValue(val);
                handleSetFirstPage(true);
              }}
              onChange={(val) => {
                handleInputChange(val);
                handleSetFirstPage(true);
              }}
              className="custom-search-input"
            />
            <Select
              showSearch
              placeholder="Select PG Status"
              optionFilterProp="children"
              onChange={handleSiteLocator}
              filterOption={selectSearchOption}
              defaultValue="All"
              value={status || "All"}
              allowClear={status ? true : false}
              onClear={() => setStatus("")}
              className="min-w-[10rem]"
              options={[
                {
                  value: "pending",
                  label: "Did not start",
                },
                {
                  value: "start",
                  label: "Journey Started",
                },
                {
                  value: "reached",
                  label: "Reached Location",
                },
                {
                  value: "finished",
                  label: "Task Solved",
                },
                {
                  value: "unrespond",
                  label: "Unrespond",
                },
                {
                  value: "pg-pending",
                  label: "PG yet to run",
                },
                {
                  value: "pg-start",
                  label: "PG Running",
                },
                {
                  value: "pg-stop",
                  label: "PG Stopped",
                },
              ]}
            />
            <IconButton
              type="primary"
              nextIcon={<UploadIcon />}
              onClick={() =>
                handleOpen({
                  type: "IMPORT",
                })
              }
            >
              Import Excel
            </IconButton>
            <Link to="/np/networks/add-tower">
              <IconButton type="primary" nextIcon={<AddIcon />}>
                Add new tower
              </IconButton>
            </Link>
          </Space>
        </Subheader>

        <NetworkTable
          keyword={keyword}
          siteCode={siteCode}
          status={status}
          handleSetFirstPage={handleSetFirstPage}
          openImport={open}
          toggleImport={toggle}
          {...rest}
        />
      </section>
    </AdminLayout>
  );
}
