import { PlusOutlined } from "@ant-design/icons";
import { Button, Form, FormProps, Input } from "antd";
import { useNavigate } from "react-router-dom";
import DeleteIcon from "../../../assets/icons/DeleteIcon";
import CustomForm from "../../../components/common/CustomForm";
import AdminLayout from "../../../layouts/AdminLayout";
import { createSupplierAsync } from "../../../store/features/NP/supplier/supplierAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { SupplierData } from "../../../types/redux/NP/supplier";

const navLink = "/np/supplier";

export default function ManageSupplier() {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [form] = Form.useForm();
  const { postLoading } = useAppSelector((state) => state.network);

  const onFinish: FormProps<SupplierData>["onFinish"] = (values) => {
    dispatch(
      createSupplierAsync({
        data: values,
        others: {
          reRender,
        },
      })
    );
  };

  // redirect and re-render function
  const reRender = (val: boolean) => {
    if (val) navigate(navLink);
  };

  return (
    <AdminLayout title="Add New Supplier" headerTitle="Add New Supplier">
      <CustomForm<SupplierData>
        onFinish={onFinish}
        title="Add New Supplier"
        formTitle="Add new supplier information"
        backLink={navLink}
        disabled={postLoading}
        formProps={{
          form,
        }}
      >
        <Form.Item
          name="supplierName"
          label="Supplier Name"
          rules={[
            {
              required: true,
              message: "Please enter supplier name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter supplier name" />
        </Form.Item>
        <Form.Item
          name="supplierAddress"
          label="Supplier Address"
          rules={[
            {
              required: true,
              message: "Please enter supplier address",
            },
          ]}
        >
          <Input size="large" placeholder="Enter supplier address" />
        </Form.Item>
        <Form.List initialValue={[undefined]} name="contactNumber">
          {(fields, { add, remove }) => {
            return (
              <div>
                {fields.map((field, index) => (
                  <div
                    className="grid grid-cols-7 items-center"
                    key={field.key}
                  >
                    <Form.Item
                      name={[index]}
                      label="Supplier Contact no"
                      rules={[
                        {
                          required: true,
                          message: "Please enter mobile no",
                        },
                        {
                          pattern: /^(\+88)?(01[3-9])[0-9]{8}$/,
                          message:
                            "Please enter a valid Bangladeshi phone number",
                        },
                      ]}
                      className="col-span-6"
                    >
                      <Input
                        size="large"
                        prefix={"BD "}
                        placeholder="Mobile No:"
                      />
                    </Form.Item>

                    {fields.length > 1 ? (
                      <div className="flex justify-end mt-1">
                        <button
                          className="w-8 h-8 rounded-full bg-[#FFDCDA] border-none cursor-pointer"
                          onClick={() => remove(field.name)}
                        >
                          <DeleteIcon />
                        </button>
                      </div>
                    ) : null}
                  </div>
                ))}
                <div className="flex justify-end mb-3">
                  <Button onClick={() => add()}>
                    <PlusOutlined /> Add Contact
                  </Button>
                </div>
              </div>
            );
          }}
        </Form.List>
      </CustomForm>
    </AdminLayout>
  );
}
