import { Form, FormProps, Input } from "antd";
import CustomForm from "../../../components/common/CustomForm";
import AdminLayout from "../../../layouts/AdminLayout";

type FieldType = {
  name: string;
};

export default function SAResourceDetails() {
  const onFinish: FormProps<FieldType>["onFinish"] = (values) => {
    console.log("Success:", values);
  };

  return (
    <AdminLayout title="Add new PG" headerTitle="Operator Name">
      <CustomForm<FieldType>
        onFinish={onFinish}
        title="Add new PG"
        formTitle="Add PG"
        backLink="/sa/resource"
      >
        <Form.Item
          name="name"
          label="Operator Name"
          rules={[
            {
              required: true,
              message: "Please enter operator name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter operator name" />
        </Form.Item>

        <Form.Item
          name="name"
          label="Contact Number"
          rules={[
            {
              required: true,
              message: "Please enter region name",
            },
          ]}
        >
          <Input size="large" placeholder="Mobile No:" />
        </Form.Item>

        <Form.Item
          name="email"
          label="Email"
          rules={[
            {
              required: true,
              message: "Please enter email name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter email" />
        </Form.Item>

        <Form.Item
          name="name"
          label="Region Name"
          rules={[
            {
              required: true,
              message: "Please enter region name",
            },
          ]}
        >
          <Input size="large" placeholder="Enter region name" />
        </Form.Item>
      </CustomForm>
    </AdminLayout>
  );
}
