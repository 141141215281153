import { ReactNode } from "react";

type Props = {
  title: string;
  children?: ReactNode;
};

export default function LocatorListItem({ title, children = "N/A" }: Props) {
  return (
    <li className="px-3 py-2 flex justify-between items-center border-b border-x-0 border-t-0 border-solid border-gray-100">
      <span className="text-status-danger-text text-sm font-medium">
        {title}
      </span>
      <span className="text-body text-sm font-medium text-right">
        {children}
      </span>
    </li>
  );
}
