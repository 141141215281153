export default function TicketCreated() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="6" fill="#FFF7E6" />
      <path
        d="M20 16.25V29.75"
        stroke="#FFC700"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.25 26.6741C13.25 26.5013 13.3097 26.3338 13.419 26.2C13.5284 26.0662 13.6806 25.9744 13.8499 25.94C14.5275 25.8016 15.1365 25.4334 15.5738 24.8976C16.0111 24.3619 16.25 23.6916 16.25 23C16.25 22.3084 16.0111 21.6381 15.5738 21.1024C15.1365 20.5666 14.5275 20.1984 13.8499 20.06C13.6806 20.0256 13.5284 19.9338 13.419 19.8C13.3097 19.6662 13.25 19.4987 13.25 19.3259V17C13.25 16.8011 13.329 16.6103 13.4697 16.4697C13.6103 16.329 13.8011 16.25 14 16.25H32C32.1989 16.25 32.3897 16.329 32.5303 16.4697C32.671 16.6103 32.75 16.8011 32.75 17V19.3259C32.75 19.4987 32.6903 19.6662 32.581 19.8C32.4716 19.9338 32.3194 20.0256 32.1501 20.06C31.4725 20.1984 30.8635 20.5667 30.4262 21.1024C29.9889 21.6381 29.75 22.3084 29.75 23C29.75 23.6916 29.9889 24.3619 30.4262 24.8976C30.8635 25.4334 31.4725 25.8016 32.1501 25.94C32.3194 25.9744 32.4716 26.0662 32.581 26.2C32.6903 26.3338 32.75 26.5013 32.75 26.6741V29C32.75 29.1989 32.671 29.3897 32.5303 29.5303C32.3897 29.671 32.1989 29.75 32 29.75H14C13.8011 29.75 13.6103 29.671 13.4697 29.5303C13.329 29.3897 13.25 29.1989 13.25 29V26.6741Z"
        stroke="#FFC700"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
