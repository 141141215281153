import { Avatar, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import {
  Link,
  useLocation,
  useParams,
  useSearchParams,
} from "react-router-dom";
import LeftArrowIcon from "../../../assets/icons/LeftArrowIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray, pathLink } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getCountUserSupplierAsync,
  getSupplierUserAsync,
} from "../../../store/features/NP/supplier/supplierAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { GetAllUsersData, TableData } from "../../../types/redux";
import { SupplierUserCountParams } from "../../../types/redux/NP/supplier";
import { AddZero, fixedNumber } from "../../../utils/number";
import { firstChar } from "../../../utils/string";
import { formatDate, minutesToHours } from "../../../utils/time";

export default function ViewSupplier() {
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const { loading, userSupplier, supplierUserCount } = useAppSelector(
    (state) => state.supplier
  );
  const { limit, page, handlePagination } = useTable();
  const supplierName = searchParams.get("name");
  const path = pathLink(location.pathname, 1);

  const columns: ColumnsType<TableData<GetAllUsersData>> = [
    {
      title: "Operator Name",
      render: (val: GetAllUsersData) => (
        <Space className="table_column_title" size={0} direction="horizontal">
          <Avatar size={45}>{firstChar(val?.firstName)}</Avatar>
          <div className="ml-[8px]">
            <h5>{`${val?.firstName} ${val?.lastName}`}</h5>
            <p className="mb-0.5">Email: {val?.email}</p>
            <p>Mobile: {val?.phoneNumber}</p>
          </div>
        </Space>
      ),
    },
    {
      title: "Total Minutes of Work",
      align: "center",
      render: (val: GetAllUsersData) => (
        <span>{minutesToHours(val.totalMinutesOfWork || 0)} min</span>
      ),
    },
    {
      title: "Total Amount",
      align: "center",
      render: (val: GetAllUsersData) => <span>{val.totalAmount || 0} BDT</span>,
    },
    {
      title: "Total Rating",
      align: "center",
      render: (val: GetAllUsersData) => (
        <span>{fixedNumber(val?.rating / val?.numberOfTask)}</span>
      ),
    },
    {
      title: "Created Date",
      align: "center",
      dataIndex: "createdAt",
      render: (val) => <span>{formatDate(val)}</span>,
    },
  ];

  useEffect(() => {
    const params: SupplierUserCountParams = {
      limit: limit,
      skip: limit * (page - 1),
      id: id as string,
    };

    dispatch(
      getSupplierUserAsync({
        params,
      })
    );
  }, [dispatch, limit, page, id]);

  useEffect(() => {
    dispatch(
      getCountUserSupplierAsync({
        params: { id: id as string },
      })
    );
  }, [dispatch, id]);

  return (
    <AdminLayout title="Supplier User List" headerTitle="Supplier User List">
      <section className="shadow-sm mt-7">
        <Subheader
          title={`${supplierName} User List`}
          subTitle={`Total Supplier User: ${AddZero(supplierUserCount)}`}
        >
          <Link to={`/${path}/supplier`}>
            <IconButton
              className="!border-others-header !text-sm !font-normal !rounded-lg !border !text-header !bg-transparent"
              type="default"
              icon={<LeftArrowIcon />}
            >
              <span>Back</span>
            </IconButton>
          </Link>
        </Subheader>

        <CustomTable<TableData<GetAllUsersData>>
          loading={loading}
          total={supplierUserCount}
          page={page}
          handlePagination={handlePagination}
          columns={columns}
          dataList={addKeyInArray(userSupplier)}
        />
      </section>
    </AdminLayout>
  );
}
