import { ReactNode } from "react";
import { Link } from "react-router-dom";
import RightUpArrow from "../../assets/icons/RightUpArrow";

type Props = {
  title: string;
  count: number;
  icon?: ReactNode;
  status?: string;
  pg?: string;
  today: boolean;
  date: {
    from: string;
    to: string;
  };
} & React.HTMLProps<HTMLDivElement>;

export default function DashboardTopbar({
  title,
  count,
  className,
  icon,
  status,
  pg,
  today,
  date,
  ...rest
}: Props) {
  const topBarData = (
    <div
      className="bg-white rounded-md border border-neutral-100 border-solid shadow-sm p-4 flex justify-between"
      {...rest}
    >
      <div className={className}>
        {icon}
        <h5 className="text-accent text-base font-medium my-2">{title}</h5>
        <h4 className="text-body text-[26px] font-bold -mb-1.5">{count}</h4>
      </div>
      {status || pg ? (
        <div className="text-accent">
          <RightUpArrow />
        </div>
      ) : null}
    </div>
  );

  if (status || pg) {
    return (
      <Link
        to={`/np/networks?status=${status || ""}&pg=${
          pg || ""
        }&today=${today}&startDate=${date?.from}&endDate=${date?.to}`}
      >
        {topBarData}
      </Link>
    );
  }

  return topBarData;
}
