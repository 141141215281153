import { App as AntApp, ConfigProvider, notification } from "antd";
import { onMessage } from "firebase/messaging";
import { Suspense, useEffect } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
// import notificationSound from "./assets/audio/notification-sound.wav";
import NotificationIcon from "./assets/icons/NotificationIcon";
import Loader from "./components/common/Loader";
import { generateToken, messaging } from "./helpers/firebaseConfig";
import useApp from "./hooks/useApp";
import useAuthChecked from "./hooks/useAuthChecked";
import SidebarLayout from "./layouts/SidebarLayout";
import AuthMiddleware from "./middleware/AuthMiddleware";
import { authRouters, publicRouters } from "./routers/routers";
import { setNotificationToken } from "./store/features/auth/loginSlice";
import { useAppDispatch, useAppSelector } from "./store/store";
import { stringToArray } from "./utils/array";

type MSGType = {
  title: string;
  body: string;
};

const App: React.FC = () => {
  useApp();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const [api, contextHolder] = notification.useNotification();
  const { authChecked } = useAuthChecked();
  const { token, siteRole, login } = useAppSelector((state) => state.login);
  const isSA = stringToArray(location.pathname, "/")?.[0] === "sa";

  if ("serviceWorker" in navigator) {
    window.addEventListener("load", () => {
      navigator.serviceWorker
        .register("/firebase-messaging-sw.js")
        .then((registration) => {
          console.log(
            "Service Worker registered with scope: ",
            registration.scope
          );
        })
        .catch((error) => {
          console.error("Service Worker registration failed: ", error);
        });
    });
  }

  // Notification with firebase
  useEffect(() => {
    generateToken().then((el) => {
      dispatch(setNotificationToken(el));
    });

    const openNotification = (msg: MSGType) => {
      if (msg?.title !== "map") {
        api.open({
          message: msg?.title,
          description: msg?.body,
          icon: <NotificationIcon />,
        });
      }
    };

    // const playSound = () => {
    //   const audio = new Audio(notificationSound);
    //   audio.play();
    // };

    onMessage(messaging, (payload) => {
      const notification = payload?.notification as MSGType;
      openNotification(notification);
      // playSound();
    });
  }, [api, dispatch]);

  if (authChecked) {
    return <Loader />;
  }

  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: isSA ? "#2497EB" : "#33AB29",
          colorPrimaryText: "#3D4A5C",
          fontFamily: "Inter",
        },
        components: {
          Button: {
            colorPrimary: "#0d0d0d",
            colorPrimaryHover: "#222",
          },
          Timeline: {
            tailColor: "#33AB29",
            itemPaddingBottom: 18,
          },
        },
      }}
    >
      <AntApp>
        {/* <Router> */}
        {contextHolder}
        <Routes>
          <Route
            path="/"
            element={
              <Navigate
                to={
                  token && login?.email
                    ? siteRole === "NP"
                      ? "/np/dashboard"
                      : siteRole === "SA"
                      ? "/sa/dashboard"
                      : siteRole === "VENDOR"
                      ? "/vendor/dashboard"
                      : "/np/login"
                    : "/np/login"
                }
              />
            }
          />

          {/* public routers */}
          {publicRouters.map(({ path, element: Element }, idx) => (
            <Route
              path={path}
              key={idx + path}
              element={
                <Suspense fallback={<Loader />}>
                  <Element />
                </Suspense>
              }
            />
          ))}

          {/* Auth routers */}
          {authRouters.map(({ path, element: Element, roles }, idx) => {
            const np = roles?.length && roles?.includes("NP");
            const sa = roles?.length && roles?.includes("SA");
            const vendor = roles?.length && roles?.includes("VENDOR");

            if (np || sa || vendor) {
              return (
                <Route
                  path={np ? "/np" : sa ? "/sa" : vendor ? "/vendor" : ""}
                  element={<SidebarLayout />}
                  key={idx + path}
                >
                  <Route
                    path={path}
                    element={
                      <AuthMiddleware roles={roles}>
                        <Suspense fallback={<Loader />}>
                          <Element />
                        </Suspense>
                      </AuthMiddleware>
                    }
                  />
                </Route>
              );
            }

            return null;
          })}
        </Routes>
        {/* </Router> */}
      </AntApp>
    </ConfigProvider>
  );
};

export default App;
