import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import AXIOS from "../../../../helpers/api";
import { BASE_API } from "../../../../helpers/apiUrl";
import { toast } from "../../../../helpers/toast";
import {
  DashboardParams,
  ErrorMessage,
  Params,
  TowerActiveInactiveData,
  TowerActiveInactiveMap,
  TowerDashboardLogMap,
} from "../../../../types/redux";

// get all active and inactive tower
export const getActiveAndInactiveTowerAsync = createAsyncThunk<
  TowerActiveInactiveData[],
  null,
  {
    rejectValue: ErrorMessage;
  }
>("np-dashboard/getActiveAndInactiveTower", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getActiveAndInactiveTower);

    return data.data?.getData as TowerActiveInactiveData[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get tower active and inactive data fail");
    return rejectWithValue(error);
  }
});

// get all active and inactive map
export const getActiveAndInactiveMapAsync = createAsyncThunk<
  TowerActiveInactiveMap[],
  null,
  {
    rejectValue: ErrorMessage;
  }
>("np-dashboard/getActiveAndInactiveMap", async (_, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getActiveAndInactiveMap);

    return data.data?.getData as TowerActiveInactiveMap[];
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast(
      "error",
      error?.message || "Get tower active and inactive map data fail"
    );
    return rejectWithValue(error);
  }
});

// get all active and inactive map
export const getDashboardLogAsync = createAsyncThunk<
  TowerDashboardLogMap,
  Params<DashboardParams>,
  {
    rejectValue: ErrorMessage;
  }
>("np-dashboard/getDashboardLog", async ({ params }, { rejectWithValue }) => {
  try {
    const data = await AXIOS.get(BASE_API.getDashboardLog, {
      params,
    });

    return data.data?.getData as TowerDashboardLogMap;
  } catch (err) {
    const errors = err as AxiosError;
    const error = errors.response?.data as ErrorMessage;
    toast("error", error?.message || "Get tower log data fail");
    return rejectWithValue(error);
  }
});
