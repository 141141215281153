import { EditFilled } from "@ant-design/icons";
import { Button, Space } from "antd";
import { ColumnsType } from "antd/es/table";
import { useEffect } from "react";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import CustomTable from "../../../components/common/CustomTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import { addKeyInArray } from "../../../helpers/siteConfig";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import {
  getAllRegionAsync,
  getCountRegionAsync,
} from "../../../store/features/NP/geoInfo/geoAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CommonParams, GetRegionData, TableData } from "../../../types/redux";
import { AddZero, countRow } from "../../../utils/number";

export default function Region() {
  const dispatch = useAppDispatch();
  const { limit, page, handlePagination } = useTable();
  const { regions, regionCount, loading } = useAppSelector(
    (state) => state.npGeo
  );

  const columns: ColumnsType<TableData<GetRegionData>> = [
    {
      title: "No.",
      align: "center",
      width: 120,
      render: (_, __, idx) => countRow(page, limit, idx),
    },
    {
      title: "Region Name",
      dataIndex: "regionName",
    },
    {
      title: "Action",
      align: "center",
      render: (val: GetRegionData) => (
        <Link to={`/sa/geo-info/region/update-region/${val?._id}`}>
          <Button type="primary" icon={<EditFilled />} />
        </Link>
      ),
    },
  ];

  useEffect(() => {
    const params: CommonParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    dispatch(
      getAllRegionAsync({
        params,
      })
    );
  }, [dispatch, limit, page]);

  useEffect(() => {
    dispatch(getCountRegionAsync());
  }, [dispatch]);

  return (
    <AdminLayout title="Region List" headerTitle="Geo Informations">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Region List"
          subTitle={`Total Region: ${AddZero(regionCount)}`}
        >
          <Space size="middle">
            <Link to="/sa/geo-info/region/add-region">
              <IconButton type="primary" nextIcon={<AddIcon />}>
                Add Region
              </IconButton>
            </Link>
          </Space>
        </Subheader>
      </section>

      <CustomTable<TableData<GetRegionData>>
        loading={loading}
        total={regionCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(regions)}
        bordered={false}
      />
    </AdminLayout>
  );
}
