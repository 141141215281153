export const BASE_API = {
  // Auth -(Account Management)
  login: "/user/login",

  // Geo Info -(GEO Setup)
  createRegion: "/geo/create-region",
  updateRegion: "/geo/update-region-by",
  getAllRegion: "/geo/get-regions",
  getSingleRegion: "/geo/get-region-by",
  getCountRegion: "/geo/count-regions",

  createArea: "/geo/create-area",
  updateArea: "/geo/update-area-by",
  getAllArea: "/geo/get-areas",
  getSingleArea: "/geo/get-area-by",
  getCountArea: "/geo/counts-area",

  createTerritory: "/geo/create-teritory",
  updateTerritory: "/geo/update-teritory-by",
  getAllTerritory: "/geo/get-teritorys",
  getSingleTerritory: "/geo/get-teritory-by",
  getCountTerritory: "/geo/counts-teritory",

  // Resource -(User)
  checkEmail: "/user/check-email",
  checkEmployeeID: "/user/check-employeeId",
  checkPhone: "/user/check-phone",
  checkNID: "/user/check-nid",

  createUser: "/user/create-user",
  getAllUsers: "/user/get-users",
  getCountUsers: "/user/counts-user",

  // Network -(Tower Management)
  checkTowerName: "/tower/check-tower-name",
  checkSiteCode: "/tower/check-site-code",

  createTower: "/tower/create-tower",
  getAllTower: "/tower/get-towers",
  getAllTower2: "/tower/get-tower-more-filter",
  getCountTower: "/tower/counts-tower",
  getCountTower2: "/tower/get-tower-count-more-filter",
  getSingleTicket: "/tower/get-tower-by",
  updateTower: "/tower/edit-tower",
  towerBulkMail: "/tower/get-bulk-mail",

  createTowerTicket: "/tower/create-ticket",
  towerTicketNotification: "/tower/send-notification-to-related-pg",
  sendFeedback: "/tower/admin-give-feedback",
  networkLogView: "/tower/get-single-tower-log",

  // Supplier
  createSupplier: "/user/create-supplier",
  getAllSupplier: "/user/get-suppliers",
  getCountSupplier: "/user/counts-suppliers",
  getSupplierUser: "/user/get-user-by-supplier",
  getCountUserSupplier: "/user/get-number-of-user-by-supplier",
  getSupplierProfile: "/user/get-suppler-profile",

  // Dashboard
  getActiveAndInactiveTower: "/tower/get-active-inactive-numer-of-tower",
  getActiveAndInactiveMap: "/tower/get-active-inactvie-route-for-map",
  getDashboardLog: "/tower/get-dashboard-log",

  // Image -(File Upload)
  fileUpload: `/tower/upload-image`,
};
