import DidNotReach from "../../../assets/icons/dashboard/DidNotReach";
import JourneyStarted from "../../../assets/icons/dashboard/JourneyStarted";
import MissedOTA from "../../../assets/icons/dashboard/MissedOTA";
import PGRunning from "../../../assets/icons/dashboard/PGRunning";
import PGStopped from "../../../assets/icons/dashboard/PGStopped";
import PGYetToRun from "../../../assets/icons/dashboard/PGYetToRun";
import ReachedLocation from "../../../assets/icons/dashboard/ReachedLocation";
import TaskComplete from "../../../assets/icons/dashboard/TaskComplete";
import TicketAccepted from "../../../assets/icons/dashboard/TicketAccepted";
import TicketCreated from "../../../assets/icons/dashboard/TicketCreated";
import TicketUnresponded from "../../../assets/icons/dashboard/TicketUnresponded";
import { useAppSelector } from "../../../store/store";
import DashboardTopbar from "../../ui/DashboardTopbar";

type Props = {
  today: boolean;
  date: {
    from: string;
    to: string;
  };
};

export default function TopBarLog({ today, date }: Props) {
  const { topBarLog } = useAppSelector((state) => state.dashboard);

  const findData = (arr: any, name: string | boolean) => {
    return arr?.find((el: any) => el?._id === name)?.Count || 0;
  };

  return (
    <div>
      <h3 className="text-body text-lg font-semibold mb-2">
        Ticket overall response:
      </h3>
      <div className="grid md:grid-cols-4 gap-5">
        <DashboardTopbar
          title="Ticket Created"
          icon={<TicketCreated />}
          count={
            findData(topBarLog?.ticketAcceptLog, "accepted") +
            findData(topBarLog?.ticketAcceptLog, "pending")
          }
          today={today}
          date={date}
        />
        <DashboardTopbar
          title="Ticket Unresponded"
          icon={<TicketUnresponded />}
          status="unrespond"
          count={findData(topBarLog?.ticketAcceptLog, "pending")}
          today={today}
          date={date}
        />
        <DashboardTopbar
          title="Ticket Accepted"
          icon={<TicketAccepted />}
          count={findData(topBarLog?.ticketAcceptLog, "accepted")}
          today={today}
          date={date}
        />
        <DashboardTopbar
          title="Task Solved"
          icon={<TaskComplete />}
          status="finished"
          count={findData(topBarLog?.towerTicketLog, "finished")}
          today={today}
          date={date}
        />
      </div>

      <h3 className="text-body text-lg font-semibold mb-2 mt-6">
        PG Journey response:
      </h3>
      <div className="grid md:grid-cols-4 gap-5">
        <DashboardTopbar
          title="Did not start"
          status="pending"
          icon={<DidNotReach />}
          count={findData(topBarLog?.towerTicketLog, "pending")}
          today={today}
          date={date}
        />
        <DashboardTopbar
          title="Journey Started"
          status="start"
          icon={<JourneyStarted />}
          count={findData(topBarLog?.towerTicketLog, "start")}
          today={today}
          date={date}
        />
        <DashboardTopbar
          title="Reached Location"
          status="reached"
          icon={<ReachedLocation />}
          count={findData(topBarLog?.towerTicketLog, "reached")}
          today={today}
          date={date}
        />
      </div>

      <h3 className="text-body text-lg font-semibold mb-2 mt-6">
        Generator Status:
      </h3>
      <div className="grid md:grid-cols-4 gap-5">
        <DashboardTopbar
          title="PG yet to run"
          pg="pending"
          icon={<PGYetToRun />}
          count={findData(topBarLog?.pgStartLog, "pending")}
          today={today}
          date={date}
        />
        <DashboardTopbar
          title="PG Running"
          pg="start"
          icon={<PGRunning />}
          count={
            findData(topBarLog?.pgStartLog, "start") -
            findData(topBarLog?.pgOffLog, "stop")
          }
          today={today}
          date={date}
        />

        <DashboardTopbar
          title="PG Stopped"
          pg="stop"
          icon={<PGStopped />}
          count={findData(topBarLog?.pgOffLog, "stop")}
          today={today}
          date={date}
        />
        <DashboardTopbar
          title="Missed OTA"
          icon={<MissedOTA />}
          count={findData(topBarLog?.pgReachStatus, false)}
          today={today}
          date={date}
        />
      </div>
    </div>
  );
}
