import { lazy } from "react";
import Alarm from "../pages/NP/Alarm/Alarm";
import NPDashboard from "../pages/NP/Dashboard";
import Area from "../pages/NP/GeoInfo/Area";
import ManageArea from "../pages/NP/GeoInfo/ManageArea";
import ManageRegion from "../pages/NP/GeoInfo/ManageRegion";
import ManageTerritory from "../pages/NP/GeoInfo/ManageTerritory";
import Region from "../pages/NP/GeoInfo/Region";
import Territory from "../pages/NP/GeoInfo/Territory";
import NPManageNetwork from "../pages/NP/Network/ManageNetwork";
import NPNetwork from "../pages/NP/Network/Network";
import NPManageResource from "../pages/NP/Resource/ManageResource";
import Resource from "../pages/NP/Resource/Resource";
import ManageSupplier from "../pages/NP/Supplier/ManageSupplier";
import Profile from "../pages/NP/Supplier/Profile";
import Supplier from "../pages/NP/Supplier/Supplier";
import ViewSupplier from "../pages/NP/Supplier/ViewSupplier";
import SADashboard from "../pages/SA/Dashboard";
import SAArea from "../pages/SA/GeoInfo/SAArea";
import SAManageArea from "../pages/SA/GeoInfo/SAManageArea";
import SAManageRegion from "../pages/SA/GeoInfo/SAManageRegion";
import SAManageTerritory from "../pages/SA/GeoInfo/SAManageTerritory";
import SARegion from "../pages/SA/GeoInfo/SARegion";
import SATerritory from "../pages/SA/GeoInfo/SATerritory";
import SALogin from "../pages/SA/Login";
import SAManageNetwork from "../pages/SA/Network/SAManageNetwork";
import SANetwork from "../pages/SA/Network/SANetwork";
import SANetworkDetails from "../pages/SA/Network/SANetworkDetails";
import SAPayment from "../pages/SA/Payment/SAPayment";
import SAManageResource from "../pages/SA/Resource/SAManageResource";
import SAResource from "../pages/SA/Resource/SAResource";
import SAResourceDetails from "../pages/SA/Resource/SAResourceDetails";
import SAManageSupplier from "../pages/SA/Supplier/ManageSupplier";
import SASupplier from "../pages/SA/Supplier/Supplier";
import SupportOperator from "../pages/SA/SupportOperator/SupportOperator";
import SATask from "../pages/SA/Task/SATask";
import VendorLogin from "../pages/Vendor/Login";
import VendorNetwork from "../pages/Vendor/Network/Network";
import { RouteType } from "../types";
const NPLogin = lazy(() => import("../pages/NP/Login"));
const NotFound = lazy(() => import("../pages/NotFound"));

// auth routers
export const authRouters: RouteType[] = [
  {
    path: "/np/dashboard",
    element: NPDashboard,
    roles: ["NP"],
  },
  {
    path: "/np/networks",
    element: NPNetwork,
    roles: ["NP"],
  },
  {
    path: "/np/alarm",
    element: Alarm,
    roles: ["NP"],
  },
  {
    path: "/np/networks/add-tower",
    element: NPManageNetwork,
    roles: ["NP"],
  },
  {
    path: "/np/networks/update-tower/:id",
    element: NPManageNetwork,
    roles: ["NP"],
  },
  // {
  //   path: "/np/support-agents",
  //   element: SupportAgent,
  //   roles: ["NP"],
  // },
  // {
  //   path: "/np/payment",
  //   element: Payment,
  //   roles: ["NP"],
  // },
  // GEO Information start
  {
    path: "/np/geo-info/region",
    element: Region,
    roles: ["NP"],
  },
  {
    path: "/np/geo-info/region/add-region",
    element: ManageRegion,
    roles: ["NP"],
  },
  {
    path: "/np/geo-info/region/update-region/:id",
    element: ManageRegion,
    roles: ["NP"],
  },
  {
    path: "/np/geo-info/area",
    element: Area,
    roles: ["NP"],
  },
  {
    path: "/np/geo-info/area/add-area",
    element: ManageArea,
    roles: ["NP"],
  },
  {
    path: "/np/geo-info/area/update-area/:id",
    element: ManageArea,
    roles: ["NP"],
  },

  {
    path: "/np/geo-info/territory",
    element: Territory,
    roles: ["NP"],
  },
  {
    path: "/np/geo-info/territory/add-territory",
    element: ManageTerritory,
    roles: ["NP"],
  },
  {
    path: "/np/geo-info/territory/update-territory/:id",
    element: ManageTerritory,
    roles: ["NP"],
  },
  // {
  //   path: "/np/geo-info/geo-details",
  //   element: GeoDetails,
  //   roles: ["NP"],
  // },
  // {
  //   path: "/np/geo-info/geo-details/add-geo-details",
  //   element: ManageGeoDetails,
  //   roles: ["NP"],
  // },
  // GEO information end

  {
    path: "/np/supplier",
    element: Supplier,
    roles: ["NP"],
  },
  {
    path: "/np/supplier/:id",
    element: ViewSupplier,
    roles: ["NP"],
  },
  {
    path: "/np/supplier/add-supplier",
    element: ManageSupplier,
    roles: ["NP"],
  },
  {
    path: "/np/resource",
    element: Resource,
    roles: ["NP"],
  },
  {
    path: "/np/resource/add-resource",
    element: NPManageResource,
    roles: ["NP"],
  },
  // {
  //   path: "/np/tasks",
  //   element: Task,
  //   roles: ["NP"],
  // },

  // ====== Support Agent ======
  {
    path: "/sa/dashboard",
    element: SADashboard,
    roles: ["SA"],
  },
  {
    path: "/sa/support-operators",
    element: SupportOperator,
    roles: ["SA"],
  },
  {
    path: "/sa/networks",
    element: SANetwork,
    roles: ["SA"],
  },
  {
    path: "/sa/alarm",
    element: Alarm,
    roles: ["SA"],
  },
  {
    path: "/sa/networks/add-tower",
    element: SAManageNetwork,
    roles: ["SA"],
  },
  {
    path: "/sa/networks/update-tower/:id",
    element: SAManageNetwork,
    roles: ["SA"],
  },
  {
    path: "/sa/networks/network-details",
    element: SANetworkDetails,
    roles: ["SA"],
  },
  {
    path: "/sa/payment",
    element: SAPayment,
    roles: ["SA"],
  },

  {
    path: "/sa/geo-info/region",
    element: SARegion,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/region/add-region",
    element: SAManageRegion,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/region/update-region/:id",
    element: SAManageRegion,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/area",
    element: SAArea,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/area/add-area",
    element: SAManageArea,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/area/update-area/:id",
    element: SAManageArea,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/territory",
    element: SATerritory,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/territory/add-territory",
    element: SAManageTerritory,
    roles: ["SA"],
  },
  {
    path: "/sa/geo-info/territory/update-territory/:id",
    element: SAManageTerritory,
    roles: ["SA"],
  },
  {
    path: "/sa/supplier",
    element: SASupplier,
    roles: ["SA"],
  },
  {
    path: "/sa/supplier/:id",
    element: ViewSupplier,
    roles: ["SA"],
  },
  {
    path: "/sa/supplier/add-supplier",
    element: SAManageSupplier,
    roles: ["SA"],
  },
  {
    path: "/sa/resource",
    element: SAResource,
    roles: ["SA"],
  },
  {
    path: "/sa/resource/add-resource",
    element: SAManageResource,
    roles: ["SA"],
  },
  {
    path: "/sa/resource/resource-details",
    element: SAResourceDetails,
    roles: ["SA"],
  },
  {
    path: "/sa/tasks",
    element: SATask,
    roles: ["SA"],
  },

  // ====== Vendor Panel ======
  // GEO Information start
  {
    path: "/vendor/dashboard",
    element: NPDashboard,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/region",
    element: Region,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/region/add-region",
    element: ManageRegion,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/region/update-region/:id",
    element: ManageRegion,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/area",
    element: Area,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/area/add-area",
    element: ManageArea,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/area/update-area/:id",
    element: ManageArea,
    roles: ["VENDOR"],
  },

  {
    path: "/vendor/geo-info/territory",
    element: Territory,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/territory/add-territory",
    element: ManageTerritory,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/geo-info/territory/update-territory/:id",
    element: ManageTerritory,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/networks",
    element: VendorNetwork,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/supplier",
    element: Profile,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/supplier/:id",
    element: ViewSupplier,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/supplier/add-supplier",
    element: ManageSupplier,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/resource",
    element: Resource,
    roles: ["VENDOR"],
  },
  {
    path: "/vendor/resource/add-resource",
    element: NPManageResource,
    roles: ["VENDOR"],
  },
];

// public routers
export const publicRouters: Omit<RouteType, "roles">[] = [
  {
    path: "/np/login",
    element: NPLogin,
  },
  {
    path: "/sa/login",
    element: SALogin,
  },
  {
    path: "/vendor/login",
    element: VendorLogin,
  },
  {
    path: "404",
    element: NotFound,
  },
  {
    path: "*",
    element: NotFound,
  },
];
