import { createSlice } from "@reduxjs/toolkit";
import {
  DashboardInitialStatus,
  TowerActiveInactiveData,
  TowerActiveInactiveMap,
  TowerDashboardLogMap,
} from "../../../../types/redux";
import {
  getActiveAndInactiveMapAsync,
  getActiveAndInactiveTowerAsync,
  getDashboardLogAsync,
} from "./dashboardAPI";

const initialState: DashboardInitialStatus<
  TowerActiveInactiveData,
  TowerActiveInactiveMap,
  TowerDashboardLogMap
> = {
  loading: false,
  activeInactiveTower: [],
  activeInactiveMap: [],
  topBarLog: null,
};

const dashboardSlice = createSlice({
  name: "np-dashboard",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // get active and inactive towers
    builder
      .addCase(getActiveAndInactiveTowerAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActiveAndInactiveTowerAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.activeInactiveTower = action.payload;
      })
      .addCase(getActiveAndInactiveTowerAsync.rejected, (state) => {
        state.loading = false;
        state.activeInactiveTower = [];
      });
    // get active and inactive map
    builder
      .addCase(getActiveAndInactiveMapAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getActiveAndInactiveMapAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.activeInactiveMap = action.payload;
      })
      .addCase(getActiveAndInactiveMapAsync.rejected, (state) => {
        state.loading = false;
        state.activeInactiveMap = [];
      });
    builder
      .addCase(getDashboardLogAsync.pending, (state) => {
        state.loading = true;
      })
      .addCase(getDashboardLogAsync.fulfilled, (state, action) => {
        state.loading = false;
        state.topBarLog = action.payload;
      })
      .addCase(getDashboardLogAsync.rejected, (state) => {
        state.loading = false;
        state.topBarLog = null;
      });
  },
});

export default dashboardSlice;
