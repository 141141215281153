export default function MissedOTA() {
  return (
    <svg
      width="46"
      height="46"
      viewBox="0 0 46 46"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="46" height="46" rx="6" fill="#E6F7FF" />
      <path
        d="M23 23L26.7123 19.2877M20.75 11.75H25.25M31.25 23C31.25 27.5563 27.5563 31.25 23 31.25C18.4437 31.25 14.75 27.5563 14.75 23C14.75 18.4437 18.4437 14.75 23 14.75C27.5563 14.75 31.25 18.4437 31.25 23Z"
        stroke="#13CFEC"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  );
}
