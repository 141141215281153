import { Button } from "antd";
import { ColumnsType } from "antd/es/table";
import { useCallback, useEffect } from "react";
import EyeIcon from "../../../assets/icons/EyeIcon";
import { addKeyInArray, pathLink } from "../../../helpers/siteConfig";
// import useModal from "../../../hooks/useModal";
import { Link, useLocation } from "react-router-dom";
import {
  getAllSupplierAsync,
  getCountSupplierAsync,
} from "../../../store/features/NP/supplier/supplierAPI";
import { useAppDispatch, useAppSelector } from "../../../store/store";
import { CustomTableProps } from "../../../types";
import {
  GetAllSupplierData,
  SupplierGetAllParams,
} from "../../../types/redux/NP/supplier";
import CustomTable from "../../common/CustomTable";
// import SupplierUser from "./SupplierUser";

type Props = {
  keyword?: string;
} & CustomTableProps;

export default function SupplierTable({
  keyword = "",
  page,
  limit,
  isFirstPage,
  handlePagination,
  handleSetFirstPage,
}: Props) {
  const location = useLocation();
  const dispatch = useAppDispatch();
  // const { limit, page, handlePagination } = useTable();
  // const { open, toggle, handleOpen } = useModal();
  const { loading, allSupplier, supplierCount } = useAppSelector(
    (state) => state.supplier
  );
  const path = pathLink(location.pathname, 1);

  const columns: ColumnsType<GetAllSupplierData> = [
    {
      title: "Supplier Name",
      dataIndex: "supplierName",
    },
    {
      title: "Supplier Address",
      dataIndex: "supplierAddress",
    },
    {
      title: "Contact No",
      dataIndex: "contactNumber",
      render: (val) =>
        val?.length ? val?.map((el: string) => <p key={el}>{el}</p>) : "N/A",
    },
    {
      title: "Action",
      align: "center",
      render: (val: GetAllSupplierData) => (
        <Link to={`/${path}/supplier/${val?._id}?name=${val?.supplierName}`}>
          <Button
            // onClick={() =>
            //   handleOpen({
            //     type: "LOG",
            //     data: val,
            //   })
            // }
            className="accent-text"
            type="text"
            icon={<EyeIcon />}
          />
        </Link>
      ),
    },
  ];

  const supplierData = useCallback(() => {
    const params: SupplierGetAllParams = {
      limit: limit,
      skip: limit * (page - 1),
    };

    const searchParams: SupplierGetAllParams = {
      supplierName: keyword,
    };

    dispatch(
      getAllSupplierAsync({
        params: keyword ? searchParams : params,
      })
    );
  }, [dispatch, keyword, limit, page]);

  useEffect(() => {
    supplierData();
  }, [supplierData]);

  useEffect(() => {
    dispatch(getCountSupplierAsync());
  }, [dispatch, keyword]);

  useEffect(() => {
    if (keyword && isFirstPage) {
      handlePagination(1, 10);
      handleSetFirstPage(false);
    }
  }, [handlePagination, handleSetFirstPage, isFirstPage, keyword]);

  return (
    <>
      {/* {open.type === "LOG" && (
        <SupplierUser
          title="Supplier User List"
          open={open.open}
          data={open?.data}
          handleClose={toggle}
        />
      )} */}
      <CustomTable<GetAllSupplierData>
        loading={loading}
        total={supplierCount}
        page={page}
        handlePagination={handlePagination}
        columns={columns}
        dataList={addKeyInArray(allSupplier)}
        isPagination={keyword ? false : true}
      />
    </>
  );
}
