import { Space } from "antd";
import Search from "antd/es/input/Search";
import { Link } from "react-router-dom";
import AddIcon from "../../../assets/icons/AddIcon";
import SupplierTable from "../../../components/NP/Supplier/SupplierTable";
import IconButton from "../../../components/common/IconButton";
import Subheader from "../../../components/ui/Subheader";
import useDebounce from "../../../hooks/useDebounce";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { useAppSelector } from "../../../store/store";
import { AddZero } from "../../../utils/number";

export default function Supplier() {
  const { supplierCount, allSupplier, loading } = useAppSelector(
    (state) => state.supplier
  );
  const { keyword, handleInputChange, handleEnterValue } = useDebounce();
  const { handleSetFirstPage, ...rest } = useTable();

  return (
    <AdminLayout title="Supplier List" headerTitle="Supplier">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Supplier List"
          subTitle={`Total Supplier: ${AddZero(
            keyword && !loading ? allSupplier?.length : supplierCount
          )}`}
        >
          <Space size="middle" className="flex-wrap">
            <Search
              placeholder="Search by name"
              onSearch={(val) => {
                handleEnterValue(val);
                handleSetFirstPage(true);
              }}
              onChange={(val) => {
                handleInputChange(val);
                handleSetFirstPage(true);
              }}
              className="custom-search-input"
            />
            <Link to="/sa/supplier/add-supplier">
              <IconButton type="primary" nextIcon={<AddIcon />}>
                Add Supplier
              </IconButton>
            </Link>
          </Space>
        </Subheader>

        <SupplierTable
          keyword={keyword}
          handleSetFirstPage={handleSetFirstPage}
          {...rest}
        />
      </section>
    </AdminLayout>
  );
}
