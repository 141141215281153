import { Select, Space } from "antd";
import Search from "antd/es/input/Search";
import { useState } from "react";
import NetworkTable from "../../../components/NP/Network/NetworkTable";
import Subheader from "../../../components/ui/Subheader";
import { selectSearchOption } from "../../../helpers/siteConfig";
import useDebounce from "../../../hooks/useDebounce";
import useModal from "../../../hooks/useModal";
import useTable from "../../../hooks/useTable";
import AdminLayout from "../../../layouts/AdminLayout";
import { useAppSelector } from "../../../store/store";
import { AddZero } from "../../../utils/number";

export default function Network() {
  const [status, setStatus] = useState("");
  const { open, toggle } = useModal();
  const { handleSetFirstPage, ...rest } = useTable();
  const { towersCount, allTowers, loading } = useAppSelector(
    (state) => state.network
  );
  const { keyword, handleInputChange, handleEnterValue } = useDebounce();

  const handleSiteLocator = (value: string) => {
    setStatus(value);
    handleSetFirstPage(true);
  };

  return (
    <AdminLayout title="Networks" headerTitle="Networks">
      <section className="shadow-sm mt-7">
        <Subheader
          title="Site Locator List"
          subTitle={`Total Network: ${AddZero(
            keyword && !loading ? allTowers?.length : towersCount
          )}`}
        >
          <Space size="middle">
            <Search
              placeholder="Search by tower name"
              onSearch={(val) => {
                handleEnterValue(val);
                handleSetFirstPage(true);
              }}
              onChange={(val) => {
                handleInputChange(val);
                handleSetFirstPage(true);
              }}
              className="custom-search-input"
            />
            <Select
              showSearch
              placeholder="Select PG Status"
              optionFilterProp="children"
              onChange={handleSiteLocator}
              filterOption={selectSearchOption}
              defaultValue="All"
              value={status || "All"}
              allowClear={status ? true : false}
              className="min-w-[10rem]"
              options={[
                {
                  value: "pending",
                  label: "Did not reach",
                },
                {
                  value: "start",
                  label: "Journey Started",
                },
                {
                  value: "reached",
                  label: "Reached Location",
                },
                {
                  value: "finished",
                  label: "Task Complete",
                },
                {
                  value: "unrespond",
                  label: "Unrespond",
                },
              ]}
            />
          </Space>
        </Subheader>

        <NetworkTable
          keyword={keyword}
          status={status}
          handleSetFirstPage={handleSetFirstPage}
          openImport={open}
          toggleImport={toggle}
          isVendor
          {...rest}
        />
      </section>
    </AdminLayout>
  );
}
